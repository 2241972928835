import React from 'react'
import { Datagrid, TextField, FunctionField } from 'react-admin'
import {
  ActionButton,
  ListPagination,
  EmptyPage,
} from '../../../common/components'
import {
  understandingChoices,
  actionsBehaviourChoices,
  presentationChoices,
} from './goalStatuses'

const GoalEditButton = ({ id, record }) => (
  <ActionButton
    type='edit'
    pathname={`/goals/${record.id}`}
    state={{ record: { memberId: id } }}
    color='primary'
  />
)

const handleRowClick = (memberId, rest) => (id, basePath, record) => {
  // have the Datagrid rowClick behave like our custom ShowButton, and pass memberId through state
  const history = rest.history
  history.push({
    pathname: `${basePath}/${record.id}/show`,
    state: { record: { memberId } },
  })
}

const getStatus = (index, choices) =>
  choices[index] && choices[index].id === index ? choices[index].name : null

export const GoalGrid = (props) => {
  const { hasList, hasEdit, hasShow, id, total, ...rest } = props
  const memberId = id
  return total > 0 ? (
    <Datagrid
      {...rest}
      rowClick={handleRowClick(memberId, rest)}
      pagination={<ListPagination />}
    >
      <TextField
        label='Area of Concern'
        source='areaOfConcern.name'
        sortable={true}
      />
      <TextField label='Goals' source='name' sortable={false} />
      <FunctionField
        sortable={false}
        label='Understanding Status'
        source='understandingStatus'
        render={(record) =>
          getStatus(record.understandingStatus, understandingChoices)
        }
      />
      <FunctionField
        label='Actions/behaviors Status'
        source='actionsBehaviorsStatus'
        sortable={false}
        render={(record) =>
          getStatus(record.actionsBehaviorsStatus, actionsBehaviourChoices)
        }
      />
      <FunctionField
        label='Presentations Status'
        source='presentationStatus'
        sortable={false}
        render={(record) =>
          getStatus(record.presentationStatus, presentationChoices)
        }
      />
      <TextField label='Created by' source='user.name' sortable={false} />
      <GoalEditButton id={memberId} />
    </Datagrid>
  ) : (
    <EmptyPage
      isNested={true}
      state={{ memberId: memberId }}
      createPath='/goals/create'
      {...rest}
    />
  )
}
