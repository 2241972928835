import React from 'react'
import {
  Edit,
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  TopToolbar,
  FunctionField,
} from 'react-admin'

import { ActionButton, UrlField } from '../../../common/components'
import {
  validAOCStatus,
  validateAOCStatus,
  ScoreMap,
} from '../../../common/utils'

const AOCTitle = ({ record }) => {
  return (
    <span>
      Edit Area of Concern{record ? `: ${record.areaOfConcern.name}` : ''}
    </span>
  )
}

const AOCEditActions = ({ basePath, data, state }) => {
  return (
    <TopToolbar>
      {data && (
        <ActionButton
          type='show'
          pathname={`${basePath}/${data.id}/show`}
          state={state}
          color='primary'
        />
      )}
    </TopToolbar>
  )
}

const AOCEditFooterToolbar = ({ memberId, redirectPath, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

export const MemberAreaOfConcernEdit = ({ theme, location, ...props }) => {
  const state = location.state ? location.state : null
  const currentMemberId = state ? state.record.memberId : null
  const redirectPath = `/members/${currentMemberId}/show/member-areas-of-concern`

  return (
    <Edit
      {...props}
      title={<AOCTitle />}
      actions={<AOCEditActions state={state} />}
    >
      <SimpleForm
        redirect={redirectPath}
        toolbar={
          <AOCEditFooterToolbar
            memberId={currentMemberId}
            redirectPath={redirectPath}
          />
        }
      >
        <UrlField
          label='Member'
          reference='members'
          source='member.name'
          linkTo='member.id'
          link='show'
        />

        <FunctionField
          label='Name'
          source='areaOfConcern.name'
          render={(record) => record.areaOfConcern.name}
        />

        <FunctionField
          label='Score'
          source='score'
          render={(record) =>
            record.score !== null ? ScoreMap[record.score].label : 'No Score'
          }
        />
        <SelectInput
          label='Status'
          source='status'
          choices={validAOCStatus}
          validate={validateAOCStatus}
          defaultValue='open'
        />
      </SimpleForm>
    </Edit>
  )
}
