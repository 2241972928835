import callLogin from './callLogin'
import changeTempPassword from './changeTempPassword'

const authProvider = {
  login: (values) => {
    return values.oldPassword ? changeTempPassword(values) : callLogin(values)
  },
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('id')
    localStorage.removeItem('name')
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('id')
      localStorage.removeItem('name')
      return Promise.reject(new Error('Unauthorized'))
    }
    return Promise.resolve()
  },
  checkAuth: (authParams) => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    /* When role isn't found in localStorage, we are resolving the promise to return a string of
      'guest'. This enables react-admin's custom routes to be accessible without authentication failing. A Promise.reject() triggers redirection to the login page. The current ver. of react-admin does not support public routes out of the box.

      https://github.com/marmelab/react-admin/issues/1647
    */
    const role = localStorage.getItem('role')
    return role ? Promise.resolve(role) : Promise.resolve('guest')
  },
}

export default authProvider
