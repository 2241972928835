import React from 'react'
import { Formik } from 'formik'
import validationSchema from './validationSchema'
import { TextField, Typography as Heading, Button } from '@material-ui/core'
import { useNotify, Notification } from 'react-admin'
import styles from '../../styles'
import fetchForgotPassword from './fetchForgotPassword'

export const ResetPasswordForm = (props) => {
  const { theme, setEmail, setIsReset } = props
  const classes = styles(theme)
  const notify = useNotify()

  return (
    <>
      <Heading component='h2' className={classes.subtitle}>
        Reset your password.
      </Heading>
      <Heading component='p' variant='body1' className={classes.body}>
        Enter your email address below and we'll send you an email with
        instructions on how to reset your password.
      </Heading>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const { email } = values
          fetchForgotPassword(email)
            .then(() => {
              setEmail(email)
              setIsReset(true)
            })
            .catch((error) => {
              return notify(`${error}`)
            })
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.form}>
            <TextField
              variant='outlined'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              type='email'
              className={classes.field}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
            />
            {props.errors.email && (
              <div id='feedback' className={classes.error}>
                {props.errors.email}
              </div>
            )}
            <Button
              id='submitButton'
              type='submit'
              fullWidth
              variant='contained'
              className={classes.submit}
            >
              Reset Password
            </Button>
          </form>
        )}
      </Formik>
      <Notification />
    </>
  )
}
