import React from 'react'
import dataProvider from '../common/api/dataProvider'
import authProvider from './AuthProvider/authProvider'
import { createBrowserHistory as createHistory } from 'history'
import customRoutes from './Routes/customRoutes'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import { Roles } from '../common/utils'

/* COMPONENTS */
import { Admin, Resource } from 'react-admin'
import { CustomLayout } from '../common/components/CustomLayout'
import { UserList, UserEdit, UserCreate, UserDetail } from './User'
import { MemberList, MemberCreate, MemberEdit, MemberDetail } from './Member'
import { QuestionCreate, QuestionEdit, QuestionDetail } from './Survey/Question'
import {
  MemberAreaOfConcernDetail,
  MemberAreaOfConcernEdit,
} from './Member/AreaOfConcern'
import { SurveyList, SurveyCreate, SurveyDetail, SurveyEdit } from './Survey'
import { TaskDetail, TaskEdit, TaskCreate, TaskList } from './Task'
import { NoteCreate, NoteEdit } from './Task/Note'
import { GoalCreate, GoalEdit, GoalDetail } from './Member/Goal'
import { LoginPage } from './Login'
import { Dashboard } from './Dashboard'

/* STYLES */
import theme from '../common/utils/theme'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded'

const history = createHistory()
const messages = { en: englishMessages }
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en', {
  allowMissing: true,
})

const App = () => (
  <Admin
    title='Roster Health'
    theme={theme}
    dashboard={Dashboard}
    customRoutes={customRoutes}
    dataProvider={dataProvider}
    authProvider={authProvider}
    history={history}
    loginPage={LoginPage}
    i18nProvider={i18nProvider}
    layout={CustomLayout}
  >
    {(permissions) => [
      permissions && permissions !== Roles.CHW ? (
        <Resource
          name='users'
          list={UserList}
          icon={AssignmentIndRoundedIcon}
          show={UserDetail}
          edit={permissions && UserEdit}
          create={permissions && UserCreate}
        />
      ) : null,
      <Resource
        name='members'
        list={MemberList}
        icon={PeopleAltIcon}
        show={MemberDetail}
        edit={MemberEdit}
        create={permissions && permissions === Roles.Admin && MemberCreate}
      />,
      <Resource
        name='surveys'
        list={SurveyList}
        icon={AssignmentOutlinedIcon}
        options={{ label: 'Surveys' }}
        create={permissions && permissions === Roles.Admin && SurveyCreate}
        show={SurveyDetail}
        edit={permissions && permissions === Roles.Admin && SurveyEdit}
      />,
      <Resource
        name='tasks'
        list={TaskList}
        icon={CheckBoxOutlinedIcon}
        options={{ label: 'Tasks' }}
        show={TaskDetail}
        edit={TaskEdit}
        create={TaskCreate}
      />,
      <Resource
        name='questions'
        create={permissions && permissions === Roles.Admin && QuestionCreate}
        edit={permissions && permissions === Roles.Admin && QuestionEdit}
        show={QuestionDetail}
      />,
      <Resource
        name='member-areas-of-concern'
        show={MemberAreaOfConcernDetail}
        edit={MemberAreaOfConcernEdit}
      />,
      <Resource name='areas-of-concern' />,
      <Resource
        name='goals'
        create={GoalCreate}
        edit={GoalEdit}
        show={GoalDetail}
      />,
      <Resource name='notes' create={NoteCreate} edit={NoteEdit} />,
      <Resource name='answers' />,
    ]}
  </Admin>
)

export default App
