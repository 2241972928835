import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  TopToolbar,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import { validateRequiredText } from '../../../common/utils'
import { ActionButton } from '../../../common/components'
import { ResponseInput } from './ResponseInput'

const useStyles = makeStyles({
  deleteButton: { margin: '0 0 0 auto' },
  listButton: { margin: '0 auto 0 0' },
  disclaimer: {
    fontStyle: 'italic',
    fontSize: '0.9rem',
    width: '100%',
    maxWidth: '500px',
  },
})

const QuestionTitle = ({ record }) => {
  return <span>Edit Question{record ? `: ${record.title}` : ''}</span>
}

const QuestionEditActions = ({ basePath, data, state }) => {
  const classes = useStyles()
  return (
    <TopToolbar>
      <ListButton
        basePath={`/surveys/${state.record.surveyId}/show/questions`}
        label='Return to Survey Questions'
        className={classes.listButton}
      />
      {data && (
        <ActionButton
          type='show'
          pathname={`${basePath}/${data.id}/show`}
          state={state}
          color='primary'
        />
      )}
    </TopToolbar>
  )
}

const QuestionEditFooterToolbar = ({ surveyId, ...props }) => {
  const classes = useStyles()
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton
        redirect={`/surveys/${surveyId}/show/questions`}
        className={classes.deleteButton}
      />
    </Toolbar>
  )
}

export const QuestionEdit = ({ theme, location, ...props }) => {
  const classes = useStyles()
  const currentSurveyId = location.state ? location.state.record.surveyId : null

  return (
    <Edit
      {...props}
      title={<QuestionTitle />}
      actions={<QuestionEditActions state={location.state} />}
    >
      <SimpleForm
        redirect={`/surveys/${currentSurveyId}/show/questions`}
        toolbar={<QuestionEditFooterToolbar surveyId={currentSurveyId} />}
      >
        <ReferenceInput
          label='Survey'
          source='surveyId'
          reference='surveys'
          link='show'
          sortBy='survey.title'
          validate={required()}
          defaultValue={currentSurveyId && currentSurveyId}
          sort={{ field: 'title', order: 'ASC' }}
        >
          <SelectInput optionText={(choice) => `${choice.title}`} />
        </ReferenceInput>
        <TextInput
          multiline
          label='Title'
          source='title'
          validate={validateRequiredText}
        />
        <ReferenceInput
          label='Area of Concern'
          source='areaOfConcernId'
          reference='areas-of-concern'
          allowEmpty
          emptyText='None'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText={(choice) => `${choice.name}`} />
        </ReferenceInput>
        <Typography component='h3' className={classes.disclaimer}>
          Mapping a score to a response will help to determine the degree of
          need the member has for the related Area of Concern. A higher scored
          answer will indicate a higher need or risk, while a lower scored
          answer will indicate a lower need or risk.
        </Typography>
        <Typography component='h3' className={classes.disclaimer}>
          Scoring can be any number between 0 and 4
        </Typography>
        <ResponseInput source='responses' label='Responses' initiateEmpty />
      </SimpleForm>
    </Edit>
  )
}
