import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import { validateRequiredText } from '../../../common/utils'
import {
  understandingChoices,
  actionsBehaviourChoices,
  presentationChoices,
} from './goalStatuses'

const GoalTitle = () => {
  return <span>Create a new goal</span>
}

export const GoalCreate = ({ theme, location, ...props }) => {
  const currentMemberId = location.state ? location.state.record.memberId : null
  return (
    <Create {...props} title={<GoalTitle />}>
      <SimpleForm redirect={`/members/${currentMemberId}/show/goals`}>
        <TextInput
          multiline
          label='Name of Goal'
          source='name'
          validate={validateRequiredText}
        />
        <ReferenceInput
          label='Area of Concern'
          source='areaOfConcernId'
          reference='areas-of-concern'
          validate={required()}
        >
          <SelectInput
            validate={required()}
            optionText={(choice) => `${choice.name}`}
          />
        </ReferenceInput>
        <TextInput multiline label='Description' source='description' />
        <SelectInput
          label='Understanding Status'
          source='understandingStatus'
          choices={understandingChoices}
          allowEmpty
          emptyValue={null}
          emptyText='No Status'
          defaultValue={null}
        />
        <SelectInput
          label='Actions/Behaviours'
          source='actionsBehaviourStatus'
          choices={actionsBehaviourChoices}
          allowEmpty
          emptyValue={null}
          emptyText='No Status'
          defaultValue={null}
        />
        <SelectInput
          label='Presentation'
          source='presentationStatus'
          choices={presentationChoices}
          allowEmpty
          emptyValue={null}
          emptyText='No Status'
          defaultValue={null}
        />
      </SimpleForm>
    </Create>
  )
}
