import React from 'react'
import get from 'lodash/get'
import { Typography, Link as Anchor } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  link: { cursor: 'pointer' },
})

export const CustomLink = ({ path, children, state, ...props }) => {
  const history = useHistory()
  const handleClick = (e) => {
    e.stopPropagation()
    state ? history.push({ pathname: path, state: state }) : history.push(path)
  }

  return (
    <Anchor onClick={handleClick} {...props}>
      {children}
    </Anchor>
  )
}

export const UrlField = ({
  record = {}, // individual record from list or page
  reference, //ie. members, users, tasks
  source, // record value to display
  linkTo, // record value to link to
  link, // edit, or show react-admin views
  basePath,
  addLabel,
  stateSource,
  ...rest
}) => {
  const classes = useStyles()
  const value = get(record, source)
  const urlPath = `/${reference}/${get(record, linkTo)}/${link}`
  const stateValue = get(record, stateSource)
  const locationState = stateSource
    ? { record: { [stateSource]: stateValue } }
    : undefined

  return (
    <CustomLink path={urlPath} state={locationState} {...rest}>
      <Typography component='span' variant='body2' className={classes.link}>
        {value}
      </Typography>
    </CustomLink>
  )
}

UrlField.defaultProps = {
  addLabel: true,
}
