import React from 'react'
import { Datagrid, TextField, FunctionField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'

import { ActionButton } from '../../../common/components'
import { ScoreMap } from '../../../common/utils/constants'
import { aocStatusColors } from '../../../common/styles'

const useStyles = makeStyles(
  {
    status: { textTransform: 'capitalize' },
    chip: { margin: 4 },
  },
  { name: 'RaChipField' }
)

const AOCEditButton = ({ id, record }) => (
  <ActionButton
    type='edit'
    pathname={`/member-areas-of-concern/${record.id}`}
    state={{ record: { memberId: id } }}
    color='primary'
  />
)

const AOCStatus = ({ classes, ...rest }) => {
  const status = rest.record && rest.record.status
  const color = aocStatusColors[status]
  return (
    <Chip
      label={status}
      className={classes.status}
      style={{ backgroundColor: color }}
    />
  )
}

const handleRowClick = (memberId, rest) => (id, basePath, record) => {
  // have the Datagrid rowClick behave like our custom ShowButton, and pass memberId through state
  const history = rest.history
  history.push({
    pathname: `${basePath}/${record.id}/show`,
    state: { record: { memberId } },
  })
}

const isValidScore = (score) => {
  const availableScores = Object.keys(ScoreMap)
  return availableScores.includes(score.toString())
}

export const MemberAreaOfConcernGrid = (props) => {
  const { hasList, hasEdit, hasShow, id, ...rest } = props
  const memberId = id
  const classes = useStyles()

  return (
    <Datagrid rowClick={handleRowClick(memberId, rest)} {...rest}>
      <TextField label='Name' source='areaOfConcern.name' sortable={false} />
      <FunctionField
        label='Status'
        sortable={false}
        render={(record) => <AOCStatus classes={classes} record={record} />}
      />
      <FunctionField
        label='Score'
        sortable={false}
        render={(record) =>
          record.score !== null && isValidScore(record.score) ? (
            <Chip
              label={ScoreMap[record.score].label}
              className={`${classes.status}`}
            />
          ) : null
        }
      />
      <AOCEditButton id={memberId} />
    </Datagrid>
  )
}
