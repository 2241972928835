import React from 'react'
import { Formik } from 'formik'
import { TextField, Typography as Heading, Button } from '@material-ui/core'
import { useNotify, Notification } from 'react-admin'
import styles from '../../styles'
import validationSchema from './validationSchema'
import fetchUpdatePassword from './fetchUpdatePassword'
import { AlertStrings } from '../../../../common/utils'

export const ConfirmPasswordForm = (props) => {
  const { theme, email, setIsConfirmed } = props
  const classes = styles(theme)
  const notify = useNotify()

  return (
    <>
      <Heading component='h2' className={classes.subtitle}>
        Confirm your new password.
      </Heading>
      <Heading component='p' variant='body1' align='center'>
        Enter the verification code sent to your email below and create your new
        password.
      </Heading>
      <Heading component='p' variant='body2' className={classes.body}>
        Hint: Choose a combination of 8-30 characters, including at least one
        UPPERCASE, one lowercase, and one number.
      </Heading>
      <Formik
        initialValues={{
          verificationCode: '',
          newPassword: '',
          passwordConfirm: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const { verificationCode, newPassword } = values
          fetchUpdatePassword({ email, verificationCode, newPassword })
            .then(() => {
              setIsConfirmed(true)
              notify(`${AlertStrings.UPDATE_PASSWORD_SUCCESS}`)
            })
            .catch((error) => notify(`${error}`))
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.form}>
            <TextField
              variant='outlined'
              required
              fullWidth
              id='verificationCode'
              label='Verification Code'
              name='verificationCode'
              type='text'
              className={classes.field}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.verificationCode}
            />
            {props.errors.verificationCode && (
              <div id='feedback' className={classes.error}>
                {props.errors.verificationCode}
              </div>
            )}
            <TextField
              variant='outlined'
              required
              fullWidth
              id='newPassword'
              label='New Password'
              name='newPassword'
              type='password'
              className={classes.field}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.newPassword}
            />
            {props.errors.newPassword && (
              <div id='feedback' className={classes.error}>
                {props.errors.newPassword}
              </div>
            )}
            <TextField
              variant='outlined'
              required
              fullWidth
              id='passwordConfirm'
              label='Confirm New Password'
              name='passwordConfirm'
              type='password'
              className={classes.field}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.passwordConfirm}
            />
            {props.errors.passwordConfirm && (
              <div id='feedback' className={classes.error}>
                {props.errors.passwordConfirm}
              </div>
            )}
            <Button
              id='confirmSubmitButton'
              type='submit'
              fullWidth
              variant='contained'
              className={classes.submit}
            >
              Update Password
            </Button>
          </form>
        )}
      </Formik>
      <Notification />
    </>
  )
}
