import {
  UnderstandingStatuses,
  ActionsBehaviourStatuses,
  PresentationStatuses,
} from '../../../common/utils'

const formatChoices = (statusArray) =>
  statusArray.reduce((acc, status, index = 1) => {
    return [...acc, { id: index, name: status }]
  }, [])

export const understandingChoices = formatChoices(UnderstandingStatuses)
export const actionsBehaviourChoices = formatChoices(ActionsBehaviourStatuses)
export const presentationChoices = formatChoices(PresentationStatuses)
