import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  FunctionField,
  EditButton,
  DeleteButton,
  ListButton,
  TopToolbar,
} from 'react-admin'
import { Roles } from '../../common/utils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  role: { textTransform: 'capitalize' },
  listButton: { margin: '0 auto 0 0' },
})

const UserTitle = ({ record }) => {
  return (
    <span>{record ? `User: ${record.firstName} ${record.lastName}` : ''}</span>
  )
}

const UserActions = ({ basePath, data, resource }) => {
  const classes = useStyles()
  return (
    <TopToolbar>
      <ListButton
        basePath={basePath}
        label='Return to User List'
        className={classes.listButton}
      />
      {data && <EditButton basePath={basePath} record={data} />}
      {data && (
        <DeleteButton basePath={basePath} record={data} resource={resource} />
      )}
    </TopToolbar>
  )
}

export const UserDetail = ({ theme, ...props }) => {
  const classes = useStyles()

  return (
    <Show title={<UserTitle />} actions={<UserActions />} {...props}>
      <SimpleShowLayout>
        <TextField label='First Name' source='firstName' />
        <TextField label='Last Name' source='lastName' />
        <EmailField label='Email Address' source='email' />
        <FunctionField
          label='Role'
          className={classes.role}
          render={(user) =>
            user.role === Roles.CHW ? user.role.toUpperCase() : user.role
          }
        />
      </SimpleShowLayout>
    </Show>
  )
}
