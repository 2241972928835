import React from 'react'

/* COMPONENTS */
import { ForgotPasswordForm } from './ForgotPasswordForm'
import {
  Container,
  Typography as Heading,
  CssBaseline,
  Avatar,
  Grid,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

/* STYLES */
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import styles from '../styles'

const SuccessMessage = ({ theme }) => {
  const classes = styles(theme)

  return (
    <>
      <Heading component='h2' className={classes.title}>
        <span
          role='img'
          aria-label='Confetti emoji. Your password has been successfully updated.'
        >
          🎉
        </span>{' '}
        All done!
      </Heading>
      <Heading component='h3' className={classes.subtitle}>
        Your password has been updated.
      </Heading>
      <Heading component='p' variant='body1' className={classes.body}>
        <Link className={classes.link} to='/'>
          Please return to the Login page to sign in.
        </Link>
      </Heading>
    </>
  )
}

export const ForgotPasswordPage = ({ theme }) => {
  const classes = styles(theme)

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.wrapper}>
        <CssBaseline />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Heading component='h1' className={classes.title}>
          Forgot your password?
        </Heading>
        <ForgotPasswordForm theme={theme}>
          <SuccessMessage theme={theme} />
        </ForgotPasswordForm>
        <Grid container>
          <Grid item xs>
            <Link className={classes.link} to='/'>
              Return to Login
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}
