import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { List, SimpleList, Datagrid, EditButton, TextField } from 'react-admin'

import { ListPagination } from '../../common/components/ListPagination'
import { Roles } from '../../common/utils'

export const SurveyList = ({ permissions, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return (
    <List
      {...props}
      pagination={<ListPagination />}
      sort={{ field: 'title', order: 'ASC' }}
      title='Surveys'
      bulkActionButtons={permissions !== Roles.Admin && false}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) => record.title}
          linkType={(record) => (record.canEdit ? 'edit' : 'show')}
        />
      ) : (
        <Datagrid rowClick='show'>
          <TextField label='Title' source='title' />
          {permissions === Roles.Admin && <EditButton />}
        </Datagrid>
      )}
    </List>
  )
}
