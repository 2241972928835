import React from 'react'
import { Filter, TextInput, SelectInput } from 'react-admin'
import { ROLES, validateRole } from '../../common/utils'
import { Roles } from '../../common/utils/constants'

export const UserFilter = ({ permissions, ...props }) => {
  // We are removing the props hasList, hasEdit, and hasShow because <Filter /> does not support them. This line is to prevent react from throwing warnings about 'Unknown Props'.
  const { hasList, hasEdit, hasShow, ...rest } = props

  return (
    <Filter {...rest}>
      <TextInput
        label='Filter by user'
        placeholder='Search by name or email'
        source='q'
        alwaysOn
      />
      {permissions === Roles.Admin && (
        <SelectInput
          source='role'
          choices={ROLES}
          allowEmpty
          emptyText='Any role'
          validate={validateRole}
          alwaysOn
        />
      )}
    </Filter>
  )
}
