import React, { useState, useEffect } from 'react'
import { useInput } from 'react-admin'
import { Box, InputLabel, TextField, Button, Grid } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

export const ResponseInput = (props) => {
  const [responses, setResponses] = useState([])
  const { input } = useInput(props)

  useEffect(() => {
    if (props.initiateEmpty && (!input.value || input.value.length === 0)) {
      return
    }
    if (input.value && input.value.length > 0) {
      return setResponses(input.value)
    }
    return setResponses([
      { label: '', score: 4 },
      { label: '', score: 3 },
      { label: '', score: 2 },
      { label: '', score: 1 },
      { label: '', score: 0 },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initiateEmpty])

  const handleChange = ({ index, label, score }) => {
    const responsesClone = responses
    if (label) {
      responsesClone[index] = { ...responsesClone[index], label }
    }
    if (score) {
      responsesClone[index] = {
        ...responsesClone[index],
        score: parseInt(score),
      }
    }

    setResponses([...responsesClone])
    input.onChange(responses)
  }

  const handleDeleteResponse = (e, index) => {
    const responsesClone = responses
    responsesClone.splice(index, 1)
    setResponses([...responsesClone])
    input.onChange(responses)
  }

  const handleAddResponse = () => {
    const responsesClone = responses
    responsesClone.push({ label: '' })
    setResponses([...responsesClone])
    input.onChange(responses)
  }

  return (
    <>
      <Box my={2}>
        <InputLabel>{props.label}</InputLabel>
      </Box>
      {responses.map((response, index) => (
        <Grid
          container
          alignItems='center'
          wrap='nowrap'
          key={index}
          spacing={2}
        >
          <Grid item>
            <InputLabel>Score</InputLabel>
          </Grid>
          <Grid item>
            <TextField
              type='number'
              inputProps={{ min: 0, max: 4 }}
              value={response.score}
              onChange={({ target }) =>
                handleChange({ index, score: target.value })
              }
            />
          </Grid>
          <Grid item>
            <InputLabel>Label</InputLabel>
          </Grid>
          <Grid item>
            <TextField
              value={response.label}
              onChange={({ target }) =>
                handleChange({ index, label: target.value })
              }
            />
          </Grid>
          <Grid item>
            <Button onClick={(e) => handleDeleteResponse(e, index)}>
              <CancelIcon />
            </Button>
          </Grid>
        </Grid>
      ))}
      <Box mt={2}>
        <Button variant='contained' onClick={handleAddResponse}>
          Add Response
        </Button>
      </Box>
    </>
  )
}
