import * as React from 'react'
import { Children, cloneElement, isValidElement, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { Layout, AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'

const CustomUserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const { children, icon, logout } = props
  if (!logout && !children) return null
  const open = Boolean(anchorEl)

  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  let role = localStorage.getItem('role')
  role = role === 'chw' ? 'CHW' : role.charAt(0).toUpperCase() + role.slice(1)
  const name = localStorage.getItem('name')

  return (
    <div>
      <Tooltip title={role}>
        <IconButton
          aria-label={role}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup={true}
          color='inherit'
          onClick={handleMenu}
        >
          {icon}
          <Typography variant='body1'>
            &nbsp;<strong>{name}</strong>
          </Typography>
        </IconButton>
      </Tooltip>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, {
                onClick: handleClose,
              })
            : null
        )}
        {logout}
      </Menu>
    </div>
  )
}

const CustomAppBar = (props) => {
  return <AppBar {...props} userMenu={<CustomUserMenu />} />
}

export const CustomLayout = (props) => {
  return <Layout appBar={CustomAppBar} {...props} />
}

CustomUserMenu.propTypes = {
  children: PropTypes.node,
  logout: PropTypes.element,
  icon: PropTypes.node,
}

CustomUserMenu.defaultProps = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />,
}
