import { makeStyles } from '@material-ui/core/styles'
import { deepPurple, red } from '@material-ui/core/colors'

export default makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: deepPurple[500],
  },
  title: {
    margin: theme.spacing(3, 0, 0),
    fontSize: '2rem',
    textAlign: 'center',
  },
  subtitle: {
    margin: theme.spacing(0, 0, 3),
    fontSize: '1.5rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  field: {
    margin: theme.spacing(1, 0),
    borderColor: deepPurple[500],
  },
  error: {
    margin: theme.spacing(0, 1),
    color: red[500],
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: deepPurple[500],
    color: '#FFF',
  },
  link: {
    color: deepPurple[500],
  },
  checkbox: {
    color: deepPurple[500],
  },
  body: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
}))
