import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  TopToolbar,
  Toolbar,
  SaveButton,
  DeleteButton,
  FormDataConsumer,
  NullableBooleanInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import {
  validTaskStatus,
  TaskInterventions,
  validateTaskStatus,
  TaskTypes,
  EscalationCategories,
  EscalationRisks,
  formatChoices,
} from '../../common/utils'
import {
  CustomDateTimeInput,
  ActionButton,
  UrlField,
  CustomReferenceSelect,
} from '../../common/components'

const taskInterventionsChoices = TaskInterventions.reduce(
  (acc, intervention) => {
    acc.push({ id: intervention, name: intervention })
    return acc
  },
  []
)

const useStyles = makeStyles({
  hidden: { display: 'none' },
  deleteButton: { margin: '0 0 0 auto' },
  button: {
    margin: '5px 5px 28px',
    verticalAlign: 'bottom',
  },
  disabledGoal: { width: '256px' },
})

const TaskTitle = ({ record }) => {
  return <span>Edit Task: {record ? `${record.name}` : ''}</span>
}

const TaskTopToolbar = ({ basePath, data, state }) => {
  return (
    <TopToolbar>
      {data && (
        <ActionButton
          type='show'
          pathname={`${basePath}/${data.id}/show`}
          state={state}
          color='primary'
        />
      )}
    </TopToolbar>
  )
}

const TaskFooterToolbar = ({ memberId, redirectPath, ...props }) => {
  const classes = useStyles()
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton redirect={redirectPath} className={classes.deleteButton} />
    </Toolbar>
  )
}

const getTaskTypeFields = (taskableType) => {
  if (taskableType && taskableType === 'escalation') {
    return (
      <>
        <Typography component='span'>Escalation Details:</Typography>
        <div>
          <NullableBooleanInput
            label='Is Clinical?'
            source='taskable.isClinical'
          />
        </div>
        <div>
          <SelectInput
            label='Category'
            source='taskable.category'
            choices={formatChoices(EscalationCategories)}
            allowEmpty
            emptyValue={null}
            defaultValue={null}
            emptyText='No Category'
          />
        </div>
        <div>
          <SelectInput
            label='Risk'
            source='taskable.risk'
            choices={formatChoices(EscalationRisks)}
            allowEmpty
            emptyValue={null}
            defaultValue={null}
            emptyText='Not Applicable'
          />
        </div>
      </>
    )
  }

  return null
}

export const TaskEdit = ({ theme, location, ...props }) => {
  const classes = useStyles()
  const state = location.state && location.state
  const currentMemberId = location.state ? location.state.record.memberId : null
  const redirectPath = currentMemberId
    ? `/members/${currentMemberId}/show/tasks`
    : `/tasks`

  return (
    <Edit
      {...props}
      title={<TaskTitle />}
      actions={<TaskTopToolbar state={state} />}
    >
      <SimpleForm
        redirect={redirectPath}
        toolbar={
          <TaskFooterToolbar
            redirectPath={redirectPath}
            memberId={currentMemberId}
          />
        }
      >
        <UrlField
          label='Member'
          reference='members'
          source='member.name'
          linkTo='member.id'
          link='show'
        />
        <TextInput label='Task name' source='name' />
        <TextInput multiline label='Description' source='description' />
        <FormDataConsumer>
          {({ formData }) =>
            formData.memberId && (
              <CustomReferenceSelect
                label='Goal'
                variant='filled'
                source='goalId'
                reference='goals'
                emptyText='None'
                params={{ memberId: formData.memberId }}
                selectedChoice={formData.goalId}
              />
            )
          }
        </FormDataConsumer>
        <SelectInput
          label='Status'
          source='status'
          choices={validTaskStatus}
          validate={validateTaskStatus}
          defaultValue='new'
        />
        <SelectInput
          label='Intervention'
          source='interventionCategory'
          choices={taskInterventionsChoices}
        />
        <CustomDateTimeInput source='dueDate' label='Due Date' />
        <NumberInput
          label='Added by user'
          source='userId'
          className={classes.hidden}
        />
        <NumberInput source='memberId' className={classes.hidden} />
        <FormDataConsumer>
          {({ record, ...rest }) => {
            return (
              <SelectInput
                label='Task Type'
                source='taskableType'
                choices={TaskTypes}
                allowEmpty
                emptyValue={null}
                defaultValue={null}
                emptyText='No Type'
                disabled
              />
            )
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { taskableType } = formData
            return getTaskTypeFields(taskableType)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
