import React, { useState } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { validateEmail, validateRequiredText, Roles } from '../../common/utils'
import { AutocompleteSelectInput } from '../../common/components'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
  button: {
    margin: '5px 5px 28px',
    verticalAlign: 'bottom',
  },
  referenceInput: { width: '256px' },
})

const MemberTitle = ({ record }) => {
  return (
    <span>
      Edit Member: {record ? `${record.firstName} ${record.lastName}` : ''}
    </span>
  )
}

const SelectCHW = (props) => {
  const classes = useStyles()
  const { assignCHW, setAssignCHW } = props

  return assignCHW ? (
    <>
      <AutocompleteSelectInput
        label='Assigned CHW'
        variant='filled'
        reference='users'
        source='userId'
        params={{ role: Roles.CHW }}
        sort={{ sort: 'name', order: 'ASC' }}
        className={classes.autoComplete}
      />
    </>
  ) : (
    <>
      <TextInput label='Assigned CHW' source='user.name' disabled />
      <Button
        variant='contained'
        className={classes.button}
        onClick={() => setAssignCHW(true)}
      >
        Reassign CHW?
      </Button>
    </>
  )
}

export const MemberEdit = ({ permissions, ...props }) => {
  const [assignCHW, setAssignCHW] = useState(false)

  return (
    <Edit title={<MemberTitle />} {...props}>
      <SimpleForm redirect='show'>
        <TextInput source='firstName' validate={validateRequiredText} />
        <TextInput source='lastName' validate={validateRequiredText} />
        <TextInput source='email' validate={validateEmail} />
        {permissions !== Roles.CHW ? (
          <SelectCHW assignCHW={assignCHW} setAssignCHW={setAssignCHW} />
        ) : null}
      </SimpleForm>
    </Edit>
  )
}
