const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const callLogin = ({ email, password }) => {
  return fetch(`${REACT_APP_API_URL}/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(({ token, role, resetPassword, id, name }) => {
      if (resetPassword) return 'reset-password'

      if (token !== undefined && role !== undefined) {
        localStorage.setItem('id', id)
        localStorage.setItem('token', token)
        localStorage.setItem('role', role)
        localStorage.setItem('name', name)
        return true
      } else {
        return null
      }
    })
}

export default callLogin
