import React from 'react'
import { Filter, TextInput } from 'react-admin'
import { Roles } from '../../common/utils'

export const TaskFilter = ({ permissions, ...props }) => {
  // We are removing the props hasList, hasEdit, and hasShow because <Filter /> does not support them. This line is to prevent react from throwing warnings about 'Unknown Props'.
  const { hasList, hasEdit, hasShow, ...rest } = props

  return (
    <Filter {...rest}>
      <TextInput
        label='Filter by task'
        placeholder='Search by name'
        source='q'
        alwaysOn
        resettable
      />
      <TextInput
        label='Filter by member'
        placeholder='Search by name'
        source='member.name'
        alwaysOn
      />
      {permissions && permissions !== Roles.CHW ? (
        <TextInput
          label='Filter by CHW'
          placeholder='Search by name'
          source='user.name'
          alwaysOn
          resettable
        />
      ) : null}
    </Filter>
  )
}
