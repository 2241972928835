import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles({
  button: {
    padding: '4px 5px',
    fontSize: '13px',
  },
  icon: {
    fontSize: '20px',
    paddingRight: '6.5px',
  },
})

export const ActionButton = ({
  type,
  pathname,
  state,
  label = type,
  variant,
  color,
  ...props
}) => {
  const classes = useStyles()
  let ActionIcon = ''
  if (type === 'edit') {
    ActionIcon = <EditIcon className={classes.icon} />
  } else if (type === 'create') {
    ActionIcon = <AddIcon className={classes.icon} />
  } else {
    ActionIcon = <VisibilityIcon className={classes.icon} />
  }

  return (
    <Button
      label={label}
      component={Link}
      className={classes.button}
      to={{
        pathname: pathname,
        state: state,
      }}
      onClick={(e) => e.stopPropagation()}
      variant={variant && variant}
      color={color && color}
    >
      {ActionIcon} {label}
    </Button>
  )
}
