import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { validateRequiredText } from '../../../common/utils'
import { ResponseInput } from './ResponseInput'

const useStyles = makeStyles({
  disclaimer: {
    fontStyle: 'italic',
    fontSize: '0.9rem',
    width: '100%',
    maxWidth: '500px',
  },
})

const QuestionTitle = () => {
  return <span>Create a new question</span>
}

export const QuestionCreate = ({ theme, location, ...props }) => {
  const classes = useStyles()
  const currentSurveyId = location.state ? location.state.record.surveyId : null

  return (
    <Create {...props} title={<QuestionTitle />}>
      <SimpleForm redirect={`/surveys/${currentSurveyId}/show/questions`}>
        <ReferenceInput
          label='Survey'
          source='surveyId'
          reference='surveys'
          link='show'
          sortBy='survey.title'
          validate={required()}
          defaultValue={currentSurveyId && currentSurveyId}
          sort={{ field: 'title', order: 'ASC' }}
        >
          <SelectInput optionText={(choice) => `${choice.title}`} />
        </ReferenceInput>
        <TextInput
          multiline
          label='Title'
          source='title'
          validate={validateRequiredText}
        />
        <ReferenceInput
          label='Area of Concern'
          source='areaOfConcernId'
          reference='areas-of-concern'
          allowEmpty
          emptyValue={0}
          emptyText='None'
          defaultValue={0}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText={(choice) => `${choice.name}`} />
        </ReferenceInput>
        <Typography component='h3' className={classes.disclaimer}>
          Mapping a score to a response will help to determine the degree of
          need the member has for the related Area of Concern. A higher scored
          answer will indicate a higher need or risk, while a lower scored
          answer will indicate a lower need or risk.
        </Typography>
        <Typography component='h3' className={classes.disclaimer}>
          Scoring can be any number between 0 and 4
        </Typography>
        <ResponseInput source='responses' label='Responses' />
      </SimpleForm>
    </Create>
  )
}
