import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import adminDashboard from './adminDashboard.png'
import managerDashboard from './managerDashboard.png'
import chwDashboard from './chwDashboard.png'

const useStyles = makeStyles({
  screenshot: {
    width: '1170px',
    marginTop: '20px',
  },
})

export const Dashboard = () => {
  const classes = useStyles()
  let role = localStorage.getItem('role')
  let image = {
    admin: adminDashboard,
    manager: managerDashboard,
    chw: chwDashboard,
  }[role]
  if (role === 'chw') {
    return (
      <a href='/members/36/show/tasks'>
        <img src={image} alt='Dashboard' className={classes.screenshot} />
      </a>
    )
  } else {
    return <img src={image} alt='Dashboard' className={classes.screenshot} />
  }
}
