import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  required,
  FormDataConsumer,
  NullableBooleanInput,
  ReferenceInput,
} from 'react-admin'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  validTaskStatus,
  validateRequiredText,
  TaskInterventions,
  validateTaskStatus,
  TaskTypes,
  EscalationCategories,
  EscalationRisks,
  formatChoices,
} from '../../common/utils'
import {
  CustomDateTimeInput,
  CustomReferenceSelect,
  AutocompleteSelectInput,
  UrlField,
} from '../../common/components'

const taskInterventionsChoices = TaskInterventions.reduce(
  (acc, intervention) => [...acc, { id: intervention, name: intervention }],
  []
)

const useStyles = makeStyles({
  hidden: { display: 'none' },
})

const TaskTitle = () => {
  return <span>Create a new task</span>
}

const getTaskTypeFields = (taskableType) => {
  if (taskableType === null) {
    return null
  }
  if (taskableType === 'escalation') {
    return (
      <>
        <Typography component='span'>Escalation Details:</Typography>
        <div>
          <NullableBooleanInput
            label='Is Clinical?'
            source='taskable.isClinical'
          />
        </div>
        <div>
          <SelectInput
            label='Category'
            source='taskable.category'
            choices={formatChoices(EscalationCategories)}
            allowEmpty
            emptyValue={null}
            defaultValue={null}
            emptyText='No Category'
          />
        </div>
        <div>
          <SelectInput
            label='Risk'
            source='taskable.risk'
            choices={formatChoices(EscalationRisks)}
            allowEmpty
            emptyValue={null}
            defaultValue={null}
            emptyText='Not Applicable'
          />
        </div>
      </>
    )
  }
  if (taskableType === 'survey') {
    return (
      <ReferenceInput
        label='Survey'
        source='taskable.surveyId'
        reference='surveys'
        link='show'
        sortBy='survey.title'
        validate={required()}
        sort={{ field: 'title', order: 'ASC' }}
      >
        <SelectInput optionText={(choice) => `${choice.title}`} />
      </ReferenceInput>
    )
  }
}

export const TaskCreate = ({ theme, location, ...props }) => {
  const currentMemberId = location.state ? location.state.record.memberId : null
  const currentUserId = localStorage.getItem('id')
  const classes = useStyles()
  const redirectPath = currentMemberId
    ? `/members/${currentMemberId}/show/tasks`
    : `/tasks`

  return (
    <Create {...props} title={<TaskTitle />}>
      <SimpleForm redirect={redirectPath}>
        {currentMemberId ? (
          <UrlField
            label='Member'
            reference='members'
            source='memberName'
            linkTo='memberId'
            link='show'
          />
        ) : (
          <AutocompleteSelectInput
            label='Member'
            variant='filled'
            reference='members'
            source='memberId'
            validate={[required()]}
          />
        )}
        <TextInput
          label='Task name'
          source='name'
          validate={validateRequiredText}
        />
        <SelectInput
          label='Intervention'
          source='interventionCategory'
          choices={taskInterventionsChoices}
          validate={required()}
        />
        <TextInput multiline label='Description' source='description' />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.memberId && (
              <CustomReferenceSelect
                label='Goal'
                variant='filled'
                source='goalId'
                reference='goals'
                params={{ memberId: formData.memberId }}
                emptyText='None'
              />
            )
          }
        </FormDataConsumer>
        <SelectInput
          label='Status'
          source='status'
          choices={validTaskStatus}
          validate={validateTaskStatus}
          defaultValue='new'
        />
        <CustomDateTimeInput source='dueDate' label='Due Date' />
        <NumberInput
          label='Added by user'
          source='userId'
          value={currentUserId}
          className={classes.hidden}
        />
        <SelectInput
          label='Task Type'
          source='taskableType'
          choices={TaskTypes}
          allowEmpty
          emptyValue={null}
          defaultValue={null}
          emptyText='No Type'
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { taskableType } = formData
            return getTaskTypeFields(taskableType)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
