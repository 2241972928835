import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { List, SimpleList } from 'react-admin'

import { Roles } from '../../common/utils/constants'
import { ListPagination } from '../../common/components/ListPagination'
import { UserFilter } from './UserFilter'
import { UserListGrid } from './UserListGrid'

const useStyles = makeStyles({
  role: { textTransform: 'capitalize' },
})

export const UserList = ({ theme, permissions, ...props }) => {
  const classes = useStyles(theme)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const currentRole = localStorage.getItem('role')
  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<ListPagination />}
      filters={<UserFilter permissions={permissions} {...props} />}
      filter={currentRole === 'manager' ? { role: Roles.CHW } : undefined}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) => `${record.firstName} ${record.lastName}`}
          secondaryText={(record) => record.email}
          tertiaryText={(record) =>
            record.role === Roles.CHW ? record.role.toUpperCase() : record.role
          }
          linkType={(record) => (record.canEdit ? 'edit' : 'show')}
        />
      ) : (
        <UserListGrid permissions={permissions} classes={classes} />
      )}
    </List>
  )
}
