import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'
import { validateEmail, validateRequiredText, Roles } from '../../common/utils'
import { AutocompleteSelectInput } from '../../common/components'

export const MemberCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='firstName' validate={validateRequiredText} />
      <TextInput source='lastName' validate={validateRequiredText} />
      <TextInput source='email' validate={[required(), validateEmail]} />
      <AutocompleteSelectInput
        label='Assigned CHW'
        variant='filled'
        reference='users'
        source='userId'
        params={{ role: Roles.CHW }}
        sort={{ sort: 'name', order: 'ASC' }}
      />
    </SimpleForm>
  </Create>
)
