import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tab, TextField, ReferenceField, ReferenceManyField } from 'react-admin'

import { SurveyTaskEdit } from './SurveyTaskEdit'
import { SurveyTaskDetail } from './SurveyTaskDetail'

const useStyles = makeStyles({
  surveyTitle: { fontSize: '1.6rem', fontWeight: '600' },
})

export const SurveyTaskTab = (props) => {
  const classes = useStyles()
  return (
    <Tab label='Survey' path='answers' {...props}>
      <ReferenceField
        label=''
        source='taskable.surveyId'
        reference='surveys'
        link='show'
      >
        <TextField source='title' className={classes.surveyTitle} />
      </ReferenceField>
      <ReferenceManyField
        reference='answers'
        target='taskId'
        addLabel={false}
        sort={{ field: 'createdAt', order: 'ASC' }}
        filter={{ taskId: props.record.id }}
      >
        {props.record.taskable.isClosed ? (
          <SurveyTaskDetail record={props.record} />
        ) : (
          <SurveyTaskEdit record={props.record} />
        )}
      </ReferenceManyField>
    </Tab>
  )
}
