import React from 'react'
import { useLogin, useNotify, Notification, useRedirect } from 'react-admin'
import validationSchema from './validationSchema'
import { AlertStrings } from '../../common/utils'

/* COMPONENTS */
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import {
  Avatar,
  CssBaseline,
  Link as Anchor,
  Box,
  Typography as Heading,
  Container,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

/* STYLES */
import styles from './styles'

export const LoginPage = ({ theme }) => {
  const classes = styles(theme)
  const login = useLogin()
  const notify = useNotify()
  const redirect = useRedirect()

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.wrapper}>
        <CssBaseline />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Heading component='h1' className={classes.title}>
          Welcome to Roster Health
        </Heading>
        <Heading component='h2' className={classes.subtitle}>
          Please Sign In
        </Heading>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const { email, password } = values
            login({ email, password })
              .then((res) => {
                if (res === 'reset-password') {
                  localStorage.setItem('userEmail', email)
                  notify(AlertStrings.CHANGE_TEMP_PROMPT)
                  redirect('/change-temporary-password')
                } else {
                  notify(AlertStrings.LOGIN_SUCCESS)
                  redirect('/')
                }
              })
              .catch((error) => notify(`${error}. ${AlertStrings.LOGIN_ERROR}`))
            actions.setSubmitting(false)
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={classes.form}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                type='email'
                className={classes.field}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email}
              />
              {props.errors.email && (
                <div id='feedback' className={classes.error}>
                  {props.errors.email}
                </div>
              )}
              <TextField
                variant='outlined'
                required
                fullWidth
                id='password'
                label='Password'
                name='password'
                autoComplete='current-password'
                type='password'
                className={classes.field}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.password}
              />
              {props.errors.password && (
                <div id='feedback' className={classes.error}>
                  {props.errors.password}
                </div>
              )}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant='body2'
                    className={classes.link}
                    to='/forgot-password'
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <Notification />
      </div>
      <Box mt={8}>
        <Heading variant='body2' color='textSecondary' align='center'>
          {`Copyright © `}
          <Anchor
            color='inherit'
            href='https://rosterhealth.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Roster Health
          </Anchor>
          {` ${new Date().getFullYear()}`}
        </Heading>
      </Box>
    </Container>
  )
}
