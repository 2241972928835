import React from 'react'
import {
  TextField,
  DateField,
  FunctionField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import {
  UrlField,
  ListPagination,
  CustomSimpleList,
} from '../../common/components'
import { TaskNoteListActions } from './Note'
import { EscalationTaskTab } from './EscalationTaskTab'
import { SurveyTaskTab } from './SurveyTask/SurveyTaskTab'

const useStyles = makeStyles({
  status: { textTransform: 'capitalize' },
})

export const TaskTabs = (props) => {
  const { record } = props
  const classes = useStyles()
  return (
    <TabbedShowLayout {...props}>
      <Tab label='Details'>
        <TextField label='Task Name' source='name' />
        <FunctionField
          label='Description'
          source='description'
          render={(record) =>
            record.description ? record.description : 'None provided.'
          }
        />
        <UrlField
          label='Goal'
          reference='goals'
          source='goal.name'
          linkTo='goal.id'
          link='show'
          stateSource='memberId'
        />
        <TextField label='Status' source='status' className={classes.status} />
        <TextField label='Intervention' source='interventionCategory' />
        <DateField
          label='Created Date'
          source='createdAt'
          showTime
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <DateField
          label='Due Date'
          source='dueDate'
          showTime
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <FunctionField
          label='Created by'
          source='user.name'
          render={(record) => (record.user ? record.user.name : 'N/A')}
        />
        <FunctionField
          label='Task Type'
          source='taskableType'
          className={classes.status}
          render={(record) =>
            record.taskableType ? record.taskableType : 'N/A'
          }
        />
      </Tab>
      {record.taskableType && record.taskableType === 'escalation' ? (
        <EscalationTaskTab />
      ) : record.taskableType && record.taskableType === 'survey' ? (
        <SurveyTaskTab record={record} />
      ) : null}
      <Tab label='Notes' path='notes'>
        <TaskNoteListActions {...props} />
        <ReferenceManyField
          reference='notes'
          target='taskId'
          addLabel={false}
          sort={{ field: 'createdAt', order: 'DESC' }}
          filter={{ taskId: record.id }}
          pagination={<ListPagination />}
        >
          <CustomSimpleList
            primaryText={(note) => note.text}
            secondaryText={(note) =>
              `${note.user.name} on ${format(new Date(note.createdAt), 'PPpp')}`
            }
            linkType='edit'
            linkState={{ taskId: record.id }}
          />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  )
}
