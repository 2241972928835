import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { validateRequiredText } from '../../common/utils/validation'

const SurveyEditTitle = ({ record }) =>
  record ? <span>Edit Survey Details: {record.name}</span> : null

export const SurveyEdit = ({ permissions, ...props }) => (
  <Edit title={<SurveyEditTitle />} {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='title' validate={validateRequiredText} />
      <TextInput source='description' multiline />
    </SimpleForm>
  </Edit>
)
