import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { InputLabel, Box } from '@material-ui/core'
import { useInput } from 'react-admin'

export const CustomDateTimeInput = (props) => {
  const { input } = useInput(props)

  return (
    <>
      <Box mb={2} pl={1}>
        <InputLabel htmlFor={input.name}>{props.label}</InputLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker {...input} value={input.value ? input.value : null} />
        </MuiPickersUtilsProvider>
      </Box>
    </>
  )
}
