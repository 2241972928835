import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  ListButton,
  FunctionField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'

import { ActionButton } from '../../../common/components'
import { Roles } from '../../../common/utils'

const useStyles = makeStyles({
  title: {
    fontSize: '1.6rem',
    fontWeight: '600',
  },
  listButton: { margin: '0 auto 0 0' },
})

const QuestionTitle = ({ record }) => {
  return <span>Survey: {record ? record.survey.title : ''}</span>
}

const QuestionShowActions = ({ basePath, data, state, permissions }) => {
  const classes = useStyles()
  return (
    <TopToolbar>
      <ListButton
        basePath={`/surveys/${state.record.surveyId}/show/questions`}
        label='Return to Survey Questions'
        className={classes.listButton}
      />
      {data && permissions === Roles.Admin && (
        <ActionButton
          type='edit'
          label='Edit Question'
          pathname={`${basePath}/${data.id}`}
          state={state}
          color='primary'
        />
      )}
    </TopToolbar>
  )
}

export const QuestionDetail = ({ theme, location, ...props }) => {
  const classes = useStyles()
  return (
    <Show
      title={<QuestionTitle />}
      actions={
        <QuestionShowActions
          state={location.state}
          permissions={props.permissions}
        />
      }
      {...props}
    >
      <SimpleShowLayout>
        <TextField label='Title' source='title' className={classes.title} />
        <FunctionField
          label='Area of Concern'
          render={(record) =>
            record.areaOfConcern ? record.areaOfConcern.name : 'None'
          }
        />
        <FunctionField
          label='Responses'
          render={(record) => {
            const { responses } = record
            if (responses && responses.length > 0) {
              return (
                <Grid container spacing={1}>
                  {responses.map((response, index) => {
                    return (
                      <Grid container spacing={2} key={index}>
                        <Grid item>
                          <Typography component='span' variant='body2'>
                            {`Score: ${response.score}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography component='span' variant='body2'>
                            {`Label: ${response.label}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            }
          }}
        />
      </SimpleShowLayout>
    </Show>
  )
}
