export const AlertStrings = {
  CHANGE_TEMP_PROMPT:
    'You are using a temporary password. Please set a new password.',
  CHANGE_TEMP_SUCCESS:
    'Your password has been changed. Welcome to Roster Health!',
  CHANGE_TEMP_ERROR:
    'Oops, something went wrong. Please check your email or password and try again!',
  LOGIN_SUCCESS: 'Welcome back to Roster Health!',
  LOGIN_ERROR: 'Invalid email or password. Please try again!',
  UPDATE_PASSWORD_SUCCESS:
    'Your password has been updated. Please return to the login page!',
  CLOSE_SURVEY_SUCCESS: 'The survey has been closed',
  CLOSE_SURVEY_ERROR: 'Oops, something went wrong. Please try again!',
}
