export const TaskInterventions = [
  'Administrative-form filing',
  'Advocacy',
  'Connecting and Organizing',
  'Coping, Social Connection',
  'Education-Teaching',
  'Documentation',
  'Monitoring',
  'Referral and Reach out',
  'Research',
  'Safety',
  'Doing',
]

export const UnderstandingStatuses = [
  'No understanding',
  'Very little understanding',
  'Some understanding',
  'Usually adequate understanding',
  'Understands completely - knows a lot',
]

export const ActionsBehaviourStatuses = [
  'No engagement or actions',
  'Rarely appropriate actions',
  'Inconsistent but sometimes appropriate actions',
  'Usually appropriate actions',
  'Consistently appropriate actions',
]

export const PresentationStatuses = [
  'Extreme Circumstances/Symptoms',
  'Severe Circumstances/Symptoms',
  'Moderate Circumstances/Symptoms',
  'Minimal Circumstances/Symptoms',
  'None, no Circumstances/Symptoms',
]

// TODO: make into constant to be referred to
export const TaskTypes = [
  { id: 'escalation', name: 'Escalation' },
  { id: 'survey', name: 'Survey' },
]

export const EscalationCategories = [
  'Clinical deterioration',
  'Medication disconnect',
  'Clinical continuity',
  'Services and equipment needs',
]

export const EscalationRisks = [
  'Important/ Necessary',
  'Beginning -Developing',
  'Urgent',
  'Emergency',
]

export const formatChoices = (statusArray) =>
  statusArray.reduce((acc, status, index = 1) => {
    return [...acc, { id: index, name: status }]
  }, [])
