import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
  TextField,
} from 'react-admin'
import { validateRequiredText } from '../../../common/utils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  hidden: { display: 'none' },
  deleteButton: { margin: '0 0 0 auto' },
  listButton: { margin: '0 auto 0 0' },
})

const NoteTitle = () => {
  return <span>Edit Note</span>
}

const NoteTopToolbar = ({ state }) => {
  const classes = useStyles()
  const currentTaskId = state ? state.record.taskId : null
  return (
    <TopToolbar>
      {state && (
        <ListButton
          basePath={`/tasks/${currentTaskId}/show/notes`}
          label='Return to Task Notes'
          className={classes.listButton}
        />
      )}
    </TopToolbar>
  )
}

const NoteFooterToolbar = ({ taskId, redirectPath, ...props }) => {
  const classes = useStyles()
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton redirect={redirectPath} className={classes.deleteButton} />
    </Toolbar>
  )
}

export const NoteEdit = ({ theme, location, ...props }) => {
  const state = location.state && location.state
  const currentTaskId = location.state ? location.state.record.taskId : null

  return (
    <Edit
      {...props}
      title={<NoteTitle />}
      actions={<NoteTopToolbar state={state} />}
    >
      <SimpleForm
        redirect={`/tasks/${currentTaskId}/show/notes`}
        toolbar={
          <NoteFooterToolbar
            redirectPath={`/tasks/${currentTaskId}/show/notes`}
            taskId={currentTaskId}
          />
        }
      >
        <TextField label='Task' reference='task' source='task.name' />
        <TextInput
          multiline
          label='Text'
          source='text'
          validate={validateRequiredText}
        />
      </SimpleForm>
    </Edit>
  )
}
