export const Roles = {
  Admin: 'admin',
  Manager: 'manager',
  CHW: 'chw',
}

export const TaskStatus = {
  NEW: 'new',
  INIT: 'initiated',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
}

export const AOCStatus = {
  Open: 'open',
  Hold: 'hold',
  Closed: 'closed',
  NotApplicable: 'n/a',
}

export const ScoreMap = {
  4: { label: 'Empowered' },
  3: { label: 'Building Capacity' },
  2: { label: 'Low Needs' },
  1: { label: 'Medium Needs' },
  0: { label: 'High Needs' },
}
