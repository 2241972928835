import React from 'react'
import validationSchema from './validationSchema'
import {
  useLogin,
  useNotify,
  Notification,
  useRedirect,
  useLogout,
} from 'react-admin'
import { AlertStrings } from '../../../common/utils'

/* COMPONENTS */
import { Formik } from 'formik'
import {
  Avatar,
  CssBaseline,
  Typography as Heading,
  Container,
  TextField,
  Button,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

/* STYLES */
import styles from '../styles'

export const ChangeTemporaryPassword = ({ theme }) => {
  const classes = styles(theme)
  const login = useLogin()
  const notify = useNotify()
  const redirect = useRedirect()
  const logout = useLogout()
  const userEmail = localStorage.getItem('userEmail')
    ? localStorage.getItem('userEmail')
    : null

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.wrapper}>
        <CssBaseline />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Heading component='h1' className={classes.title}>
          Change Your Temporary Password
        </Heading>
        <Heading component='h2' variant='body1' className={classes.body}>
          Check your inbox for an email containing your temporary password.
        </Heading>
        <Formik
          initialValues={{
            email: userEmail ? userEmail : '',
            oldPassword: '',
            newPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const { email, oldPassword, newPassword } = values
            logout()
            login({ email, oldPassword, newPassword })
              .then((res) => {
                notify(AlertStrings.CHANGE_TEMP_SUCCESS)
                redirect('/')
                return res
              })
              .catch((error) =>
                notify(`${error}. ${AlertStrings.CHANGE_TEMP_ERROR}`)
              )
            localStorage.removeItem('userEmail')
            actions.setSubmitting(false)
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={classes.form}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                type='email'
                className={classes.field}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email}
              />
              {props.errors.email && (
                <div id='feedback' className={classes.error}>
                  {props.errors.email}
                </div>
              )}
              <TextField
                variant='outlined'
                required
                fullWidth
                id='oldPassword'
                label='Old Password'
                name='oldPassword'
                autoComplete='current-password'
                type='password'
                className={classes.field}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.oldPassword}
              />
              {props.errors.oldPassword && (
                <div id='feedback' className={classes.error}>
                  {props.errors.oldPassword}
                </div>
              )}
              <TextField
                variant='outlined'
                required
                fullWidth
                id='newPassword'
                label='New Password'
                name='newPassword'
                type='password'
                className={classes.field}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.newPassword}
              />
              {props.errors.newPassword && (
                <div id='feedback' className={classes.error}>
                  {props.errors.newPassword}
                </div>
              )}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.submit}
              >
                Update Password & Sign In
              </Button>
            </form>
          )}
        </Formik>
        <Notification />
      </div>
    </Container>
  )
}
