import React from 'react'
import { Show, DeleteButton, TopToolbar, ListButton } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import { ActionButton } from '../../common/components'
import { TaskTabs } from './TaskTabs'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  listButton: { margin: '0 auto 0 0' },
})

const TaskTitle = ({ record }) => {
  return <span>Member: {record ? `${record.member.name}` : ''}</span>
}

const TaskShowActions = ({ basePath, data, state, resource }) => {
  const classes = useStyles()
  const { member, name } = data || {}
  const redirectPath = member && `/members/${member.id}/show/tasks`

  return (
    <TopToolbar>
      {member && (
        <div className={classes.listButton}>
          <Typography variant='body1'>
            <strong>Task:</strong> {name}
          </Typography>
          <ListButton basePath={redirectPath} label='Return to Member Tasks' />
        </div>
      )}
      {data && (
        <ActionButton
          type='edit'
          label='Edit task'
          pathname={`${basePath}/${data.id}`}
          state={state}
          color='primary'
        />
      )}
      <DeleteButton
        basePath={basePath}
        record={data}
        resource={resource}
        redirect={redirectPath}
      />
    </TopToolbar>
  )
}

export const TaskDetail = ({ theme, permissions, location, ...props }) => {
  return (
    <Show
      title={<TaskTitle />}
      actions={<TaskShowActions state={location.state} />}
      {...props}
    >
      <TaskTabs location={location} permissions={permissions} />
    </Show>
  )
}
