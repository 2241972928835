import React from 'react'
import { Tab, TextField, FunctionField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import {
  EscalationCategories,
  EscalationRisks,
  formatChoices,
} from '../../common/utils'

const useStyles = makeStyles({
  status: { textTransform: 'capitalize' },
})

// TODO: abstract into helper
const getChoice = (index, choices, defaultChoice) =>
  choices[index] && choices[index].id === index
    ? choices[index].name
    : defaultChoice

export const EscalationTaskTab = (props) => {
  const classes = useStyles()
  return (
    <Tab label='Escalation' {...props}>
      <TextField
        label='Is Clinical?'
        source='taskable.isClinical'
        className={classes.status}
      />
      <FunctionField
        label='Escalation Category'
        source='taskable.category'
        sortable={false}
        render={(record) =>
          getChoice(
            record.taskable.category,
            formatChoices(EscalationCategories),
            'No Category'
          )
        }
      />
      <FunctionField
        label='Escalation Risks'
        source='taskable.risk'
        sortable={false}
        render={(record) =>
          getChoice(
            record.taskable.category,
            formatChoices(EscalationRisks),
            'Not Applicable'
          )
        }
      />
    </Tab>
  )
}
