import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  TopToolbar,
  SaveButton,
  Toolbar,
  DeleteButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import { validateRequiredText } from '../../../common/utils'
import { ActionButton, UrlField } from '../../../common/components'
import {
  understandingChoices,
  actionsBehaviourChoices,
  presentationChoices,
} from './goalStatuses'

const useStyles = makeStyles({
  deleteButton: { margin: '0 0 0 auto' },
})

const GoalTitle = ({ record }) => {
  return <span>Edit Goal{record ? `: ${record.name}` : ''}</span>
}

const GoalEditActions = ({ basePath, data, state }) => {
  return (
    <TopToolbar>
      {data && (
        <ActionButton
          type='show'
          pathname={`${basePath}/${data.id}/show`}
          state={state}
          color='primary'
        />
      )}
    </TopToolbar>
  )
}

const GoalEditFooterToolbar = ({ memberId, ...props }) => {
  const classes = useStyles()
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton
        redirect={`/members/${memberId}/show/goals`}
        className={classes.deleteButton}
      />
    </Toolbar>
  )
}

export const GoalEdit = ({ theme, location, ...props }) => {
  const currentMemberId = location.state ? location.state.record.memberId : null

  return (
    <Edit
      {...props}
      title={<GoalTitle />}
      actions={<GoalEditActions state={location.state} />}
    >
      <SimpleForm
        redirect={`/members/${currentMemberId}/show/goals`}
        toolbar={<GoalEditFooterToolbar memberId={currentMemberId} />}
      >
        <UrlField
          label='Member'
          reference='members'
          source='member.name'
          linkTo='member.id'
          link='show'
        />
        <TextInput
          multiline
          label='Name of Goal'
          source='name'
          validate={validateRequiredText}
        />
        <ReferenceInput
          label='Area of Concern'
          source='areaOfConcern.id'
          reference='areas-of-concern'
          validate={required()}
        >
          <SelectInput optionText={(choice) => `${choice.name}`} />
        </ReferenceInput>
        <TextInput multiline label='Description' source='description' />
        <SelectInput
          allowEmpty
          emptyText='None'
          label='Understanding Status'
          source='understandingStatus'
          choices={understandingChoices}
        />
        <SelectInput
          allowEmpty
          emptyText='None'
          label='Actions/Behaviors'
          source='actionsBehaviorsStatus'
          choices={actionsBehaviourChoices}
        />
        <SelectInput
          allowEmpty
          emptyText='None'
          label='Presentation'
          source='presentationStatus'
          choices={presentationChoices}
        />
        <UrlField
          label='Created by'
          reference='users'
          source='user.name'
          linkTo='user.id'
          link='show'
        />
      </SimpleForm>
    </Edit>
  )
}
