import React, { useState } from 'react'
import { ResetPasswordForm } from './ResetPasswordForm'
import { ConfirmPasswordForm } from './ConfirmPasswordForm'

export const ForgotPasswordForm = ({ theme, children }) => {
  const [email, setEmail] = useState('')
  const [isReset, setIsReset] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const form = isReset ? (
    <ConfirmPasswordForm
      theme={theme}
      email={email}
      setIsConfirmed={setIsConfirmed}
    />
  ) : (
    <ResetPasswordForm
      theme={theme}
      setEmail={setEmail}
      setIsReset={setIsReset}
    />
  )

  const successMessage = children ? (
    [children]
  ) : (
    <h2>Success! Your password has been updated.</h2>
  )

  if (isConfirmed) {
    return successMessage
  } else {
    return form
  }
}
