import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  ListButton,
  FunctionField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { ActionButton } from '../../../common/components'
import { ScoreMap } from '../../../common/utils'

const useStyles = makeStyles({
  listButton: { margin: '0 auto 0 0' },
  status: { textTransform: 'capitalize' },
})

const MemberAreaOfConcernTitle = ({ record }) => {
  return <span>Member: {record ? record.member.name : ''}</span>
}

const MemberAreaOfConcernActions = ({ basePath, data, state, resource }) => {
  const classes = useStyles()
  const memberId = state.record.memberId
  const redirectPath = `/members/${memberId}/show/member-areas-of-concern`

  return (
    <TopToolbar>
      <ListButton
        basePath={redirectPath}
        label='Return to Member Areas of Concern'
        className={classes.listButton}
      />
      {data && (
        <ActionButton
          type='edit'
          pathname={`${basePath}/${data.id}`}
          state={state}
          color='primary'
        />
      )}
    </TopToolbar>
  )
}

export const MemberAreaOfConcernDetail = ({ theme, location, ...props }) => {
  const classes = useStyles(theme)
  const state = location.state && location.state

  return (
    <Show
      title={<MemberAreaOfConcernTitle />}
      actions={<MemberAreaOfConcernActions state={state} />}
      {...props}
    >
      <SimpleShowLayout>
        <Typography variant='h6' paragraph>
          Area of concern
          <Divider />
        </Typography>

        <TextField label='Name' source='areaOfConcern.name' />
        <FunctionField
          label='Score'
          source='score'
          render={(record) =>
            record.score !== null ? ScoreMap[record.score].label : 'No Score'
          }
        />
        <TextField label='Status' className={classes.status} source='status' />
      </SimpleShowLayout>
    </Show>
  )
}
