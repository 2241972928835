import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from 'react-admin'
import {
  ROLES,
  validateEmail,
  validateRole,
  validateRequiredText,
  Roles,
} from '../../common/utils'

const CHW_ROLE = ROLES.filter((role) => role.id === Roles.CHW)

export const UserCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm redirect='list'>
        <TextInput source='firstName' validate={validateRequiredText} />
        <TextInput source='lastName' validate={validateRequiredText} />
        <TextInput source='email' validate={[required(), validateEmail]} />
        <SelectInput
          source='role'
          choices={permissions === Roles.Manager ? CHW_ROLE : ROLES}
          validate={validateRole}
          defaultValue={Roles.CHW}
        />
      </SimpleForm>
    </Create>
  )
}
