import React from 'react'
import { Roles } from '../../common/utils'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, CardActions } from '@material-ui/core'
import {
  Tab,
  Show,
  TextField,
  EmailField,
  TabbedShowLayout,
  ReferenceManyField,
  ListButton,
} from 'react-admin'

import { ScoreMap } from '../../common/utils'
import {
  UrlField,
  ListPagination,
  ActionButton,
  CustomSimpleList,
} from '../../common/components'
import { MemberTaskGrid, MemberTaskListActions } from './Task'
import { MemberAreaOfConcernGrid } from './AreaOfConcern'
import { GoalGrid, GoalListActions } from './Goal'
import memberDetails from './memberDetails.png'
import memberBiometrics from './memberBiometrics.png'

const useStyles = makeStyles({
  status: { textTransform: 'capitalize' },
  link: { cursor: 'pointer' },
  listButton: { margin: '0 auto 0 0' },
  screenshot: {
    width: '1170px',
    marginLeft: -20,
  },
  details: {
    marginLeft: 20,
  },
})

const MemberTitle = ({ record }) => {
  return (
    <span>
      {record ? `Member: ${record.firstName} ${record.lastName}` : ''}
    </span>
  )
}

const MemberActions = ({ basePath, data }) => {
  const classes = useStyles()

  return (
    <CardActions>
      <ListButton
        basePath={basePath}
        label='Return to Member List'
        className={classes.listButton}
      />
      {data && (
        <ActionButton
          type='edit'
          label='Edit Member'
          pathname={`${basePath}/${data.id}`}
          color='primary'
        />
      )}
    </CardActions>
  )
}

export const MemberDetail = ({ theme, location, permissions, ...props }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { id } = props

  return (
    <Show
      {...props}
      title={<MemberTitle />}
      actions={<MemberActions state={location.state} />}
    >
      <TabbedShowLayout className={classes.details}>
        <Tab label='Details'>
          <TextField label='First Name' source='firstName' />
          <TextField label='Last Name' source='lastName' />
          {permissions !== Roles.CHW ? (
            <UrlField
              label='Assigned CHW'
              reference='users'
              source='user.name'
              linkTo='user.id'
              link='show'
            />
          ) : null}
          <EmailField label='Email Address' source='email' />
          <img
            src={memberDetails}
            alt='Member details'
            className={classes.screenshot}
          />
        </Tab>
        <Tab label='Biometrics'>
          <img
            src={memberBiometrics}
            alt='Member biometrics'
            className={classes.screenshot}
          />
        </Tab>

        <Tab label='Areas of Concern' path='member-areas-of-concern'>
          <ReferenceManyField
            reference='member-areas-of-concern'
            target='memberId'
            addLabel={false}
            filter={{ memberId: id }}
            perPage={10}
            sort={{ field: 'areaOfConcern.name', order: 'ASC' }}
            pagination={<ListPagination />}
          >
            {isMobile ? (
              <CustomSimpleList
                primaryText={(record) => record.areaOfConcernName}
                secondaryText={(record) =>
                  record.score !== null
                    ? ScoreMap[record.score].label
                    : record.status
                }
                tertiaryText={(record) => {
                  return record.score ? record.status : null
                }}
                linkState={{ memberId: id }}
                linkType='edit'
              />
            ) : (
              <MemberAreaOfConcernGrid {...props} />
            )}
          </ReferenceManyField>
        </Tab>
        <Tab label='Goals' path='goals'>
          {/* TODO: Find a way to add filters to Datagrid */}
          <GoalListActions {...props} />
          <ReferenceManyField
            reference='goals'
            target='memberId'
            addLabel={false}
            sort={{ field: 'areaOfConcern.name', order: 'ASC' }}
            filter={{ memberId: id }}
            perPage={10}
            pagination={<ListPagination />}
          >
            {isMobile ? (
              <CustomSimpleList
                primaryText={(goal) => goal.name}
                linkType={(record) => (record.canEdit ? 'edit' : 'show')}
                linkState={{ memberId: id }}
              />
            ) : (
              <GoalGrid {...props} />
            )}
          </ReferenceManyField>
        </Tab>
        <Tab label='Tasks' path='tasks'>
          {/* TODO: Find a way to add filters to Datagrid */}
          <MemberTaskListActions {...props} />
          <ReferenceManyField
            reference='tasks'
            target='memberId'
            addLabel={false}
            sort={{ field: 'dueDate', order: 'ASC' }}
            filter={{ memberId: id }}
            perPage={10}
            pagination={<ListPagination />}
          >
            {isMobile ? (
              <CustomSimpleList
                primaryText={(task) => task.name}
                linkType={(record) => (record.canEdit ? 'edit' : 'show')}
                linkState={{ memberId: id }}
              />
            ) : (
              <MemberTaskGrid classes={classes} {...props} />
            )}
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
