export const taskStatusColors = {
  new: '#cbc9ed',
  initiated: '#eeeeee',
  inProgress: '#f6da98',
  completed: '#c8e8aa',
}

export const aocStatusColors = {
  open: '#cbc9ed',
  'n/a': '#eeeeee',
  hold: '#f6da98',
  closed: '#c8e8aa',
}
