import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import { validateRequiredText } from '../../common/utils/validation'

export const SurveyCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='title' validate={validateRequiredText} />
      <TextInput source='description' multiline />
    </SimpleForm>
  </Create>
)
