const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const changeTempPassword = ({ email, oldPassword, newPassword }) => {
  return fetch(`${REACT_APP_API_URL}/change-temporary-password`, {
    method: 'POST',
    body: JSON.stringify({ email, oldPassword, newPassword }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(({ token, role, id, name }) => {
      if (token !== undefined && role !== undefined) {
        localStorage.setItem('token', token)
        localStorage.setItem('role', role)
        localStorage.setItem('id', id)
        localStorage.setItem('name', name)
        return true
      } else {
        return null
      }
    })
}

export default changeTempPassword
