import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
  List,
  SimpleList,
  Datagrid,
  EmailField,
  EditButton,
  FunctionField,
} from 'react-admin'
import { MemberFilter } from './MemberFilter'
import { UrlField, ListPagination } from '../../common/components'
import { Roles } from '../../common/utils'

export const MemberList = ({ permissions, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<MemberFilter permissions={permissions} {...props} />}
      pagination={<ListPagination />}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) => `${record.firstName} ${record.lastName}`}
          secondaryText={(record) => record.email}
          tertiaryText={(record) => record.role}
          linkType={(record) => (record.canEdit ? 'edit' : 'show')}
        />
      ) : (
        <Datagrid rowClick='show'>
          <FunctionField
            label='Name'
            render={(member) => `${member.firstName} ${member.lastName}`}
            sortBy='name'
          />
          <EmailField label='Email Address' source='email' />
          {permissions !== Roles.CHW && (
            <UrlField
              label='CHW'
              reference='users'
              source='user.name'
              linkTo='user.id'
              link='show'
            />
          )}
          <EditButton />
        </Datagrid>
      )}
    </List>
  )
}
