import { fetchUtils } from 'react-admin'
import jsonServerProvider from 'ra-data-json-server'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const token = localStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const { REACT_APP_API_URL } = process.env
const dataProvider = jsonServerProvider(REACT_APP_API_URL, httpClient)

export default dataProvider
