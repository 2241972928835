import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SimpleListLoading, sanitizeListRestProps } from 'react-admin'
import { ListItem, ListItemText, List } from '@material-ui/core'

import { CustomLink } from './'

const useStyles = makeStyles(
  {
    tertiary: { float: 'right', opacity: 0.541176 },
  },
  { name: 'RaSimpleList' }
)

const useLinkStyles = makeStyles(
  {
    link: {
      textDecoration: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },
  },
  { name: 'RaLinkOrNot' }
)

export const CustomSimpleList = (props) => {
  const {
    basePath,
    linkState,
    className,
    classes: classesOverride,
    data,
    hasBulkActions,
    ids,
    loaded,
    loading,
    leftAvatar,
    leftIcon,
    linkType,
    onToggleItem,
    primaryText,
    rightAvatar,
    rightIcon,
    secondaryText,
    selectedIds,
    tertiaryText,
    total,
    ...rest
  } = props
  const classes = useStyles(props)
  const linkClasses = useLinkStyles(props)

  if (loaded === false) {
    return (
      <SimpleListLoading
        classes={classes}
        className={className}
        hasLeftAvatarOrIcon={!!leftIcon || !!leftAvatar}
        hasRightAvatarOrIcon={!!rightIcon || !!rightAvatar}
        hasSecondaryText={!!secondaryText}
        hasTertiaryText={!!tertiaryText}
      />
    )
  }
  const dataIds = Object.keys(data)

  return (
    total > 0 && (
      <List className={className} {...sanitizeListRestProps(rest)}>
        {dataIds.map((id) => {
          return (
            <CustomLink
              key={id}
              state={{ record: linkState }}
              path={
                linkType === 'edit'
                  ? `${basePath}/${data[id].id}`
                  : `${basePath}/${data[id].id}/show`
              }
              className={linkClasses.link}
            >
              <ListItem>
                <ListItemText
                  primary={
                    <div>
                      {primaryText(data[id], id)}
                      {tertiaryText && (
                        <span className={classes.tertiary}>
                          {tertiaryText(data[id], id)}
                        </span>
                      )}
                    </div>
                  }
                  secondary={secondaryText && secondaryText(data[id], id)}
                />
              </ListItem>
            </CustomLink>
          )
        })}
      </List>
    )
  )
}
