import React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin'
import {
  ROLES,
  validateEmail,
  validateRole,
  validateRequiredText,
  Roles,
} from '../../common/utils'

const CHW_ROLE = ROLES.filter((role) => role.id === Roles.CHW)

const UserTitle = ({ record }) => {
  return (
    <span>
      Edit User: {record ? `${record.firstName} ${record.lastName}` : ''}
    </span>
  )
}

const EditRole = ({ record, permissions }) => {
  const currentUserId = localStorage.getItem('id')
  return (
    <SelectInput
      source='role'
      choices={permissions === Roles.Manager ? CHW_ROLE : ROLES}
      validate={validateRole}
      disabled={currentUserId === record.id.toString()}
      record={record}
    />
  )
}

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='firstName' validate={validateRequiredText} />
      <TextInput source='lastName' validate={validateRequiredText} />
      <TextInput source='email' validate={validateEmail} />
      <EditRole {...props} />
    </SimpleForm>
  </Edit>
)
