import { email, choices, required, minLength } from 'react-admin'
import { Roles, TaskStatus, AOCStatus } from './constants'
import * as Yup from 'yup'

export const ROLES = Object.keys(Roles).map((role) => {
  return { id: Roles[role], name: role }
})

export const validateRequiredText = [required(), minLength(2)]

export const validateEmail = email()

export const validateRole = choices(
  ROLES.map((role) => role.id),
  'Not a valid role'
)

// TO DO: Improve this mapping
export const validTaskStatus = [
  { id: TaskStatus.NEW, name: 'New' },
  { id: TaskStatus.INIT, name: 'Initiated' },
  { id: TaskStatus.IN_PROGRESS, name: 'In Progress' },
  { id: TaskStatus.COMPLETED, name: 'Completed' },
]

// TO DO: Improve this mapping
export const validAOCStatus = [
  { id: AOCStatus.Open, name: 'Open' },
  { id: AOCStatus.Hold, name: 'Hold' },
  { id: AOCStatus.Closed, name: 'Closed' },
  { id: AOCStatus.NotApplicable, name: 'N/A' },
]

const validateStatus = (statusArray) => {
  return choices(
    statusArray.map((status) => status.id),
    'Not a valid status'
  )
}

export const validateTaskStatus = validateStatus(validTaskStatus)
export const validateAOCStatus = validateStatus(validAOCStatus)

export const passwordSchema = Yup.string()
  .min(8)
  .matches(/[a-z]+/, 'Must contain a lower case letter')
  .matches(/[A-Z]+/, 'Must contain an upper case letter')
  .matches(/[0-9]+/, 'Must contain a number')
  .required('Required. Please provide a password')
