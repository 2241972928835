import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  SimpleList,
  Datagrid,
  DateField,
  EditButton,
  TextField,
} from 'react-admin'
import { TaskFilter } from './index'
import { Roles } from '../../common/utils'
import { UrlField, ListPagination } from '../../common/components'

const useStyles = makeStyles({
  status: { textTransform: 'capitalize' },
})

export const TaskList = ({ permissions, ...props }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <List
      {...props}
      title='Tasks'
      pagination={<ListPagination />}
      filters={<TaskFilter permissions={permissions} />}
      sort={{ field: 'dueDate', order: 'ASC' }}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(task) => task.name}
          secondaryText={(task) => task.interventionCategory}
          tertiaryText={(task) => task.status}
          linkType={(record) => (record.canEdit ? 'edit' : 'show')}
        />
      ) : (
        <Datagrid rowClick='show'>
          <TextField label='Task' source='name' />
          <UrlField
            label='Member'
            reference='members'
            source='member.name'
            linkTo='member.id'
            link='show'
          />
          {permissions !== Roles.CHW && (
            <UrlField
              label='Created by'
              reference='users'
              source='user.name'
              linkTo='user.id'
              link='show'
            />
          )}
          <TextField label='Intervention' source='interventionCategory' />
          <TextField
            label='Status'
            source='status'
            className={classes.status}
          />
          <TextField
            label='Task Type'
            source='taskableType'
            className={classes.status}
          />
          <DateField
            label='Due Date'
            source='dueDate'
            showTime
            options={{
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            }}
          />
          <DateField
            label='Created Date'
            source='createdAt'
            showTime
            options={{
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            }}
          />
          <EditButton />
        </Datagrid>
      )}
    </List>
  )
}
