import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { useInput } from 'react-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const useStyles = makeStyles({
  autoComplete: {
    width: '256px',
    marginBottom: '25px',
    display: 'inline-block',
  },
})

export const AutocompleteSelectInput = (props) => {
  const classes = useStyles()
  const { input, isRequired, meta } = useInput(props)
  const { label, variant, reference, source, sort, params } = props
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const loading = open && options.length === 0
  const sortString = sort ? new URLSearchParams(sort).toString() : ''
  const paramString = params ? new URLSearchParams(params).toString() : ''

  const fetchQuery = useCallback(
    async (active, query = null) => {
      let url = `${REACT_APP_API_URL}/${reference}?${sortString}${
        params && sortString
          ? `&${paramString}`
          : !sortString && params && `${paramString}`
      }`

      if (query) url = `${url}&q=${query}`
      const bearerToken =
        localStorage.getItem('token') && localStorage.getItem('token')

      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: `${bearerToken}`,
        }),
      })
      const choices = response.status === 200 ? await response.json() : []

      if (active) {
        choices.length > 0 && setOptions(choices)
      }
    },
    [paramString, params, reference, sortString]
  )

  const handleInputChange = (e, value, reason) => {
    if (reason !== 'input') return
    let active = true
    fetchQuery(active, value)
    return () => {
      active = false
    }
  }

  const handleOnChange = (e, value, reason) => {
    const fieldValue = value ? value.id : ''
    input.onChange(fieldValue)
  }

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    fetchQuery(active)

    return () => {
      active = false
    }
  }, [loading, fetchQuery])

  useEffect(() => {
    if (!open) setOptions([])
  }, [open])

  return (
    <Autocomplete
      required={isRequired}
      open={open}
      className={classes.autoComplete}
      options={options}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      getOptionLabel={(option) =>
        option ? `${option.firstName} ${option.lastName}` : ''
      }
      noOptionsText='No Results Found'
      renderInput={(params) => (
        <TextField
          {...params}
          name={source}
          label={isRequired ? `${label} *` : label}
          variant={variant}
          value={params.inputProps.value}
          error={isRequired && !!(meta.touched && 'Required')}
          helperText={isRequired && meta.touched && 'Required'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
