import React, { cloneElement } from 'react'
import { TopToolbar, sanitizeListRestProps } from 'react-admin'
import { ActionButton } from '../../../common/components'

export const TaskNoteListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate = false,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasList,
  hasEdit,
  hasShow,
  id,
  record,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <ActionButton
      type='create'
      label='Create Note'
      pathname='/notes/create'
      state={{ record: { taskId: record.id, taskName: record.name } }}
      color='primary'
    />
  </TopToolbar>
)
