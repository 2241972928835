import React from 'react'
import { Route } from 'react-router-dom'
import { ChangeTemporaryPassword } from '../Login/ChangeTemporaryPassword'
import { ForgotPasswordPage } from '../Login/ForgotPassword'

export default [
  <Route
    exact
    path='/change-temporary-password'
    render={() => <ChangeTemporaryPassword />}
    noLayout
  />,
  <Route
    exact
    path='/forgot-password'
    render={() => <ForgotPasswordPage />}
    noLayout
  />,
]
