import React from 'react'
import { useHistory } from 'react-router-dom'
import { Datagrid, EmailField, EditButton, FunctionField } from 'react-admin'
import { Button } from '@material-ui/core'

import { Roles } from '../../common/utils/constants'

export const CaseloadButton = ({ userId }) => {
  const history = useHistory()
  const handleClick = (e) => {
    e.stopPropagation()
    history.push(
      `/members?displayedFilters={"userId"%3Atrue}&filter={"userId"%3A${userId}}`
    )
  }
  return <Button onClick={handleClick}>View Caseload</Button>
}

export const UserListGrid = (props) => {
  const { classes, ...rest } = props

  return (
    <Datagrid rowClick='show' {...rest}>
      <FunctionField
        label='Full Name'
        render={(user) => `${user.firstName} ${user.lastName}`}
        sortBy='name'
      />
      <FunctionField
        label='Role'
        className={classes.role && classes.role}
        render={(user) =>
          user.role === Roles.CHW ? user.role.toUpperCase() : user.role
        }
      />
      <EmailField label='Email Address' source='email' />
      <FunctionField
        render={(record) =>
          record.role === Roles.CHW && (
            <CaseloadButton userId={record.id} {...rest} />
          )
        }
      />
      <EditButton />
    </Datagrid>
  )
}
