import React, { useState, useCallback, useEffect } from 'react'
import { useInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const bearerToken =
  localStorage.getItem('token') && localStorage.getItem('token')

const useStyles = makeStyles({
  formControl: {
    height: '48px',
    width: '256px',
    marginBottom: '25px',
    display: 'inline-block',
  },
  select: { width: '100%' },
})

export const CustomReferenceSelect = (props) => {
  const {
    label, // input label
    source, // value to save
    reference, // endpoint
    params, // parameters to pass into call
    sort,
    variant,
    emptyText,
    emptyValue,
    disabled,
    selectedChoice,
  } = props
  const classes = useStyles()
  const { input } = useInput(props)
  const sortString = sort ? new URLSearchParams(sort).toString() : ''
  const paramString = params ? new URLSearchParams(params).toString() : ''
  const [options, setOptions] = useState([])
  const [choice, setChoice] = useState('')
  const [open, setOpen] = useState(false)
  /*  Workaround for Material UI's Select ignoring empty string as a value
      https://github.com/mui-org/material-ui/issues/8581
  */
  const emptyString = null

  const fetchOptions = useCallback(async () => {
    const url = `${REACT_APP_API_URL}/${reference}?${sortString}${
      params && sortString
        ? `&${paramString}`
        : !sortString && params && `${paramString}`
    }`

    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: `${bearerToken}`,
      }),
    })
    const choices = response.status === 200 ? await response.json() : []
    setOptions(choices)
  }, [params, paramString, reference, sortString])

  const handleChange = (e, change) => {
    setChoice(e.target.value)
    input.onChange(e.target.value)
  }

  useEffect(() => {
    fetchOptions()
    if (selectedChoice) {
      setChoice(selectedChoice)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOptions])

  return (
    <FormControl
      variant={variant}
      className={classes.formControl}
      disabled={disabled}
    >
      <InputLabel id='customReferenceSelect'>{label}</InputLabel>
      <Select
        displayEmpty
        defaultValue={emptyString}
        className={classes.select}
        labelId='customReferenceSelect'
        name={source}
        value={choice ? choice : emptyString}
        onChange={handleChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {emptyText ? (
          <MenuItem value={emptyValue ? emptyValue : emptyString}>
            {emptyText}
          </MenuItem>
        ) : null}
        {options &&
          options.map(({ name, id }, index) => (
            <MenuItem key={index} value={id}>
              {name && name.length > 0 ? name : 'No name'}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

CustomReferenceSelect.defaultProps = {
  addLabel: true,
}
