import React from 'react'
import { Datagrid, TextField, DateField, ChipField } from 'react-admin'
import {
  ActionButton,
  ListPagination,
  EmptyPage,
} from '../../../common/components'
import { taskStatusColors } from '../../../common/styles'

const TaskEditButton = ({ id, record }) => (
  <ActionButton
    type='edit'
    pathname={`/tasks/${record.id}`}
    state={{ record: { memberId: id } }}
    color='primary'
  />
)

const TaskStatus = ({ classes, ...rest }) => {
  const status = rest.record && rest.record.status
  const color = taskStatusColors[status]
  return (
    <ChipField
      {...rest}
      label='Status'
      source='status'
      className={classes.status}
      style={{ backgroundColor: color }}
    />
  )
}

const handleRowClick = (memberId, rest) => (id, basePath, record) => {
  // have the Datagrid rowClick behave like our custom ShowButton, and pass memberId through state
  const history = rest.history
  history.push({
    pathname: `${basePath}/${record.id}/show`,
    state: { record: { memberId } },
  })
}

export const MemberTaskGrid = (props) => {
  const { hasList, hasEdit, hasShow, id, total, classes, ...rest } = props
  const memberId = id

  return total > 0 ? (
    <Datagrid
      {...rest}
      rowClick={handleRowClick(memberId, rest)}
      pagination={<ListPagination />}
    >
      <TextField label='Name' source='name' />
      <TextField label='Intervention' source='interventionCategory' />
      <TaskStatus classes={classes} />
      <TextField
        label='Task Type'
        source='taskableType'
        className={classes.status}
      />
      <DateField
        label='Due Date'
        source='dueDate'
        showTime
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        }}
      />
      <DateField
        label='Created Date'
        source='createdAt'
        showTime
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        }}
      />
      <TaskEditButton id={memberId} />
    </Datagrid>
  ) : (
    <EmptyPage
      isNested={true}
      state={{ memberId: memberId }}
      createPath='/tasks/create'
      {...rest}
    />
  )
}
