import React from 'react'
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
} from 'react-admin'
import { useMediaQuery } from '@material-ui/core'

import { QuestionGrid, QuestionListActions } from './Question'
import { ListPagination, CustomSimpleList } from '../../common/components'
import { Roles } from '../../common/utils'

const SurveyTitle = ({ record }) => {
  return <span>Survey: {record ? `${record.title}` : ''}</span>
}

export const SurveyDetail = ({ theme, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { id } = props
  return (
    <Show title={<SurveyTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label='Details'>
          <TextField label='Title' source='title' />
          <TextField label='Description' source='description' />
        </Tab>
        <Tab label='Questions' path='questions'>
          {props.permissions === Roles.Admin && (
            <QuestionListActions {...props} />
          )}
          <ReferenceManyField
            reference='questions'
            target='surveyId'
            addLabel={false}
            sort={{ field: 'title', order: 'DESC' }}
            filter={{ surveyId: id }}
            pagination={<ListPagination />}
            bulkActionButtons={props.permissions !== Roles.Admin && false}
          >
            {isMobile ? (
              <CustomSimpleList
                primaryText={(question) => question.title}
                linkType={(record) => (record.canEdit ? 'edit' : 'show')}
                linkState={{ surveyId: id }}
              />
            ) : (
              <QuestionGrid {...props} />
            )}
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
