import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  ListButton,
  DeleteButton,
  FunctionField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { ActionButton } from '../../../common/components'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {
  understandingChoices,
  actionsBehaviourChoices,
  presentationChoices,
} from './goalStatuses'

const useStyles = makeStyles({
  listButton: { margin: '0 auto 0 0' },
})

const GoalTitle = ({ record }) => {
  return <span>Member: {record ? record.member.name : ''}</span>
}

const getStatus = (index, choices) =>
  choices[index] && choices[index].id === index
    ? choices[index].name
    : 'No Status'

const GoalShowActions = ({ basePath, data, state, resource }) => {
  const classes = useStyles()
  const memberId = state.record.memberId
  const redirectPath = `/members/${memberId}/show/goals`

  return (
    <TopToolbar>
      <ListButton
        basePath={redirectPath}
        label='Return to Member Goals'
        className={classes.listButton}
      />
      {data && (
        <ActionButton
          type='edit'
          pathname={`${basePath}/${data.id}`}
          state={state}
          color='primary'
        />
      )}
      <DeleteButton
        basePath={basePath}
        record={data}
        resource={resource}
        redirect={redirectPath}
      />
    </TopToolbar>
  )
}

export const GoalDetail = ({ theme, permissions, location, ...props }) => {
  const state = location.state && location.state

  return (
    <Show
      title={<GoalTitle />}
      actions={<GoalShowActions state={state} />}
      {...props}
    >
      <SimpleShowLayout>
        <Typography variant='h6' paragraph>
          Goal
          <Divider />
        </Typography>

        <TextField label='Name' source='name' />
        <FunctionField
          label='Description'
          source='description'
          render={(record) =>
            record.description ? record.description : 'None provided.'
          }
        />
        <FunctionField
          label='Area of Concern'
          source='areaOfConcern.name'
          render={(record) =>
            record.areaOfConcern ? record.areaOfConcern.name : 'None'
          }
        />
        <FunctionField
          sortable={false}
          label='Understanding'
          source='understandingStatus'
          render={(record) =>
            getStatus(record.understandingStatus, understandingChoices)
          }
        />
        <FunctionField
          label='Actions/behaviors'
          source='actionsBehaviorsStatus'
          sortable={false}
          render={(record) =>
            getStatus(record.actionsBehaviorsStatus, actionsBehaviourChoices)
          }
        />
        <FunctionField
          label='Presentations'
          source='presentationStatus'
          sortable={false}
          render={(record) =>
            getStatus(record.presentationStatus, presentationChoices)
          }
        />
        <FunctionField
          label='Created by'
          source='user.name'
          render={(record) => (record.user ? record.user.name : 'N/A')}
        />
      </SimpleShowLayout>
    </Show>
  )
}
