import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SimpleListLoading } from 'react-admin'
import {
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Card,
  Chip,
} from '@material-ui/core'

const useListStyles = makeStyles(
  {
    tertiary: { float: 'right', opacity: 0.541176 },
  },
  { name: 'RaSimpleList' }
)

const useStyles = makeStyles({
  disclaimer: { fontStyle: 'italic', fontSize: '0.9rem' },
})

export const SurveyTaskDetail = (props) => {
  const {
    className,
    data,
    ids,
    leftAvatar,
    leftIcon,
    loaded,
    rightAvatar,
    rightIcon,
    secondaryText,
    tertiaryText,
    total,
  } = props
  const listClasses = useListStyles(props)
  const classes = useStyles(props)

  if (loaded === false) {
    return (
      <SimpleListLoading
        classes={listClasses}
        className={className}
        hasLeftAvatarOrIcon={!!leftIcon || !!leftAvatar}
        hasRightAvatarOrIcon={!!rightIcon || !!rightAvatar}
        hasSecondaryText={!!secondaryText}
        hasTertiaryText={!!tertiaryText}
      />
    )
  }

  const getDisabledInputs = (question) => {
    return (
      <RadioGroup>
        {question.responses.map((response, id) => (
          <FormControlLabel
            key={id}
            fullWidth={true}
            row={false}
            checked={question.value === id}
            value={response.id}
            disabled
            control={<Radio />}
            label={response.label}
          />
        ))}
      </RadioGroup>
    )
  }

  return (
    total > 0 && (
      <Box mb={4}>
        <Box>
          <Typography component='h3' className={classes.disclaimer}>
            This survey has been closed
          </Typography>
        </Box>
        {ids.map((id) => {
          const question = data[id]
          return (
            <Box key={id} mt={4}>
              <Card>
                <Box p={2}>
                  {question.areaOfConcern && (
                    <Box mb={2}>
                      <Chip label={question.areaOfConcern.name} />
                    </Box>
                  )}
                  <Typography component='h4'>{question.title}</Typography>
                  {getDisabledInputs(question)}
                  {question.comment && (
                    <TextField
                      disabled
                      value={question.comment}
                      multiline
                      label='Comment'
                      variant='filled'
                    />
                  )}
                </Box>
              </Card>
            </Box>
          )
        })}
      </Box>
    )
  )
}
