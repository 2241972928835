import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  SimpleListLoading,
  SimpleForm,
  Edit,
  RadioButtonGroupInput,
  TextInput,
  useNotify,
  useRedirect,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { Typography, Box, Button, Chip } from '@material-ui/core'

import { AlertStrings } from '../../../common/utils'

const useStyles = makeStyles(
  {
    tertiary: { float: 'right', opacity: 0.541176 },
  },
  { name: 'RaSimpleList' }
)

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileToolbar: {
    position: 'relative',
  },
  spacer: { height: '0px' },
})

export const SurveyTaskEdit = (props) => {
  const {
    basePath,
    className,
    data,
    ids,
    leftAvatar,
    leftIcon,
    loaded,
    rightAvatar,
    rightIcon,
    secondaryText,
    tertiaryText,
    total,
    record,
    resource,
  } = props
  const classes = useStyles(props)
  const notify = useNotify()
  const redirect = useRedirect()

  if (loaded === false) {
    return (
      <SimpleListLoading
        classes={classes}
        className={className}
        hasLeftAvatarOrIcon={!!leftIcon || !!leftAvatar}
        hasRightAvatarOrIcon={!!rightIcon || !!rightAvatar}
        hasSecondaryText={!!secondaryText}
        hasTertiaryText={!!tertiaryText}
      />
    )
  }

  const formatResponseChoices = (responses) =>
    responses.map((response, index) => {
      return { id: index, name: response.label }
    })

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton redirect={false} />
      </Toolbar>
    )
  }

  const handleCloseSurvey = async () => {
    const payload = {
      ...record,
      taskable: { ...record.taskable, isClosed: true },
    }

    const url = `${process.env.REACT_APP_API_URL}/tasks/${record.id}`
    const bearerToken =
      localStorage.getItem('token') && localStorage.getItem('token')

    fetch(url, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        authorization: `${bearerToken}`,
      }),
      body: JSON.stringify(payload),
    })
      .then((res) => {
        notify(AlertStrings.CLOSE_SURVEY_SUCCESS)
        redirect(`/members/${record.memberId}/show/tasks`)
        return res
      })
      .catch((error) => {
        notify(`${error}. ${AlertStrings.CLOSE_SURVEY_ERROR}`)
      })
  }

  return (
    total > 0 && (
      <Box mt={2}>
        {ids.map((id) => {
          const question = data[id]
          return (
            <Box key={id} mt={4}>
              {question.areaOfConcern && (
                <Box mb={2}>
                  <Chip label={question.areaOfConcern.name} />
                </Box>
              )}
              <Typography component='h4'>{question.title}</Typography>
              <Edit
                {...{ basePath, data, ids, loaded, resource, total }}
                id={question.id}
                title={<div />}
              >
                <SimpleForm toolbar={<CustomToolbar />}>
                  {question.responses && (
                    <RadioButtonGroupInput
                      label='Answer'
                      source='value'
                      fullWidth={true}
                      row={false}
                      choices={formatResponseChoices(question.responses)}
                      required={true}
                    />
                  )}
                  <TextInput label='Comment' source='comment' multiline />
                </SimpleForm>
              </Edit>
            </Box>
          )
        })}
        <Box my={3} display='flex'>
          <Box>
            <Button
              onClick={handleCloseSurvey}
              variant='contained'
              color='secondary'
            >
              Close Survey
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              onClick={() => redirect(`/members/${record.memberId}/show/tasks`)}
              variant='contained'
              color='secondary'
            >
              Back to Tasks
            </Button>
          </Box>
        </Box>
      </Box>
    )
  )
}
