import React from 'react'
import { Datagrid, TextField } from 'react-admin'

import {
  ActionButton,
  ListPagination,
  EmptyPage,
} from '../../../common/components'
import { Roles } from '../../../common/utils'

const QuestionEditButton = ({ id, record }) => (
  <ActionButton
    type='edit'
    pathname={`/questions/${record.id}`}
    state={{ record: { surveyId: id } }}
    color='primary'
  />
)

const handleRowClick = (surveyId, rest) => (id, basePath, record) => {
  // have the Datagrid rowClick behave like our custom ShowButton, and pass surveyId through state
  const history = rest.history
  history.push({
    pathname: `${basePath}/${record.id}/show`,
    state: { record: { surveyId } },
  })
}

export const QuestionGrid = (props) => {
  const { hasList, hasEdit, hasShow, id, ...rest } = props
  const surveyId = id
  if (props.data && Object.keys(props.data).length > 0) {
    return (
      <Datagrid
        {...rest}
        rowClick={handleRowClick(surveyId, rest)}
        pagination={<ListPagination />}
      >
        <TextField label='Title' source='title' sortable={false} />
        <TextField
          label='Area of Concern'
          source='areaOfConcern.name'
          sortable={false}
        />
        {props.permissions === Roles.Admin && (
          <QuestionEditButton id={surveyId} />
        )}
      </Datagrid>
    )
  }
  return (
    <EmptyPage
      createPath='/questions/create'
      state={{ surveyId: surveyId }}
      {...rest}
    />
  )
}
