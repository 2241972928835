import * as Yup from 'yup'
import { passwordSchema } from '../../../../common/utils/validation'

export default Yup.object().shape({
  verificationCode: Yup.number().required(
    'Required. Please check your email for the code'
  ),
  newPassword: passwordSchema,
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
    .required('Please re-type your new password'),
})
