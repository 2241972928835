import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  TextField,
  NumberInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { UrlField } from '../../../common/components'

import { validateRequiredText } from '../../../common/utils'

const NoteTitle = () => {
  return <span>Create a new note</span>
}

const useStyles = makeStyles({
  hidden: { display: 'none' },
})

export const NoteCreate = ({ theme, location, ...props }) => {
  const classes = useStyles()
  const currentTaskId = location.state ? location.state.record.taskId : null

  return (
    <Create {...props} title={<NoteTitle />}>
      <SimpleForm redirect={`/tasks/${currentTaskId}/show/notes`}>
        <TextField label='Task' source='taskName' />
        <NumberInput
          source='taskId'
          defaultValue={currentTaskId}
          className={classes.hidden}
        />

        <UrlField
          label='Member'
          reference='members'
          source='member.name'
          linkTo='member.id'
          link='show'
        />

        <TextInput
          multiline
          label='Text'
          source='text'
          validate={validateRequiredText}
        />
      </SimpleForm>
    </Create>
  )
}
