const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const fetchForgotPassword = async (email) => {
  return await fetch(`${REACT_APP_API_URL}/forgot-password`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(async (response) => {
    if (response.status < 200 || response.status >= 300) {
      const res = await response.json()
      throw new Error(res.error)
    }
    return response.json()
  })
}

export default fetchForgotPassword
