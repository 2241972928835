import React from 'react'
import { Box } from '@material-ui/core'
import Inbox from '@material-ui/icons/Inbox'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { ActionButton } from './'

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
)

export const EmptyPage = (props) => {
  const { resource, createPath, isNested, state } = props
  const classes = useStyles(props)
  return (
    <Box pb={2}>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant='h4' paragraph>
          {`No ${resource} yet.`}
        </Typography>
        <Typography variant='body1'>Do you want to add one?</Typography>
      </div>
      <div className={classes.toolbar}>
        {!isNested && (
          <ActionButton
            type='create'
            pathname={createPath}
            state={{ record: state }}
            variant='contained'
            color='primary'
          />
        )}
      </div>
    </Box>
  )
}
